import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import useDropdowns from 'src/hooks/useDropdowns'
import { AddUnit } from 'src/redux/actions'
import { uploadToS3 } from 'src/util/uploadToS3'
import * as Yup from 'yup'

import { StatusList } from '../../util/constants'
import { hrs, minutes } from '../../util/helpers'
import VideoThumbnailField from '../exercise/FormTypes/Fields/VideoThumbnailField'
import VideoUploaderField from '../exercise/FormTypes/Fields/VideoUploaderField'
import DropdownMenu from '../helperComponents/DropdownMenu'
import LoadingButton from '../helperComponents/LoadingButton'

const AddEditCourse = ({ selectedRow, toggleDrawer, refetch }) => {
  const { dropdowns } = useDropdowns()
  const courseDifficultyLevel = useSelector(
    store => store.mainReducer.courseDifficultyLevel
  )

  const initialValues = {
    name: (selectedRow && selectedRow?.name) || '',
    description: (selectedRow && selectedRow?.description) || '',
    course: (selectedRow && selectedRow?.course?._id) || '',
    difficultyLevel:
      (selectedRow && selectedRow?.difficultyLevel) || 'interestingStuff',
    sortOrder: (selectedRow && selectedRow?.sortOrder) || '',
    video: selectedRow?.video || '',
    videoThumbnail: selectedRow?.videoThumbnail || '',
    isActive:
      selectedRow && selectedRow.isActive === undefined
        ? true
        : selectedRow && selectedRow?.isActive,
    hrs: selectedRow?.hrs || 0,
    minutes: selectedRow?.minutes || 0,
    unPublished:
      selectedRow && selectedRow.unPublished === undefined
        ? false
        : selectedRow && selectedRow?.unPublished,
  }
  const dispatch = useDispatch()

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(1, 'Minimum 1 letters').max(50, 'Maximum 50 characters').required('Required'),
    sortOrder: Yup.number().min(0),
    description: Yup.string().min(5, 'Minimum 5 letters').max(200, 'Maximum 200 characters').required('Required'),
    course: Yup.string().min(5, 'Minimum 5 letters').required('Required'),
  })

  const handleCancel = () => {
    toggleDrawer(false)
  }

  const handleSubmit = async values => {
    // same shape as initial values
    const reqData = selectedRow._id
      ? { ...values, _id: selectedRow._id }
      : { ...values }
    for (let key in reqData) {
      if (reqData[key] instanceof File) {
        let file = reqData[key]
        await uploadToS3(file)
        reqData[key] = file.name
      }
    }
    toggleDrawer(false)
    reqData.hrs = reqData.hrs ? reqData.hrs : 0
    reqData.minutes = reqData.minutes ? reqData.minutes : 0
    await dispatch(AddUnit(reqData))
    refetch()
  }

  const onThumbnailChange = ({ target: { files } }, setFieldValue) => {
    setFieldValue('videoThumbnail', files[0])
  }

  const onVideoChange = ({ target: { files } }, setFieldValue) => {
    setFieldValue('video', files[0])
  }

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Container maxWidth="sm">
          <Typography color="textPrimary" variant="h4">
            {selectedRow?.id ? 'Edit' : 'Add'} a Course
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              values,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldError,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Name"
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      required
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      helperText={touched.description && errors.description}
                      label="Description"
                      margin="normal"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      required
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl
                      error={Boolean(touched.course && errors.course)}
                      fullWidth
                    >
                      <InputLabel id="demo-simple-select-label">
                        Language
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values?.course}
                        label="Language"
                        name="course"
                        onChange={e => {
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        required
                      >
                        {dropdowns?.courses?.map(course => {
                          return (
                            <MenuItem value={course.key}>
                              {course.value}
                            </MenuItem>
                          )
                        })}
                      </Select>
                      <FormHelperText>
                        {touched.course && errors.course}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.difficultyLevel}
                        label="Difficulty Level"
                        name="difficultyLevel"
                        onChange={handleChange}
                        isRequired={true}
                        data={courseDifficultyLevel}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <VideoThumbnailField
                      imageUrl={values?.videoThumbnail}
                      onChange={e => onThumbnailChange(e, setFieldValue)}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <VideoUploaderField
                      onChange={e => onVideoChange(e, setFieldValue)}
                      // isDisabled={isDisabled}
                      src={values?.exerciseVideo}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.isActive}
                        label="Status"
                        name="isActive"
                        isRequired={true}
                        onChange={handleChange}
                        data={StatusList}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.unPublished}
                        label="Unpublished"
                        name="unPublished"
                        isRequired={true}
                        onChange={handleChange}
                        data={StatusList}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Sort Order"
                      margin="normal"
                      inputProps={{ min: 0 }}
                      name="sortOrder"
                      type="number"
                      defaultValue={0}
                      onChange={handleChange}
                      value={values?.sortOrder}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography style={{ font: 'inherit' }}>
                      Duration of Course
                    </Typography>
                    <Stack p={2} flexDirection={'row'} rowGap={1}>
                      <FormControlLabel
                        label={
                          <Typography style={{ padding: '10px' }}>
                            Hrs
                          </Typography>
                        }
                        control={
                          <DropdownMenu
                            value={values.hrs}
                            name="hrs"
                            style={{ paddingLeft: '20px' }}
                            onChange={handleChange}
                            isRequired={true}
                            data={hrs}
                          />
                        }
                      ></FormControlLabel>
                      <FormControlLabel
                        label={
                          <Typography style={{ padding: '10px' }}>
                            Minutes
                          </Typography>
                        }
                        control={
                          <DropdownMenu
                            value={values.minutes}
                            name="minutes"
                            onChange={handleChange}
                            isRequired={true}
                            data={minutes}
                          />
                        }
                      ></FormControlLabel>
                    </Stack>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <LoadingButton
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      name="Save"
                    ></LoadingButton>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      color="primary"
                      onClick={handleCancel}
                      // disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="button"
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  )
}

export default AddEditCourse
