import { combineReducers } from 'redux'
import * as ActionTypes from 'src/redux/actionTypes'
import { errorReducer } from 'src/redux/errorReducer'
import LocalStorageService from 'src/util/localStorageService'

import {
  countries,
  courseCategories,
  courseDifficultyLevel,
  exerciseCategories,
  exerciseOptionTypes,
  states,
  surnames,
} from './constants'

const initialState = {
  auth: LocalStorageService.getAuthInfo(),
  countries,
  states,
  surnames,
  courses: [],
  coursesStaleOn: null,
  units: [],
  unitsStaleOn: null,
  chapters: [],
  chaptersStaleOn: null,
  banners: [],
  bannersStaleOn: null,
  lessons: [],
  lessonsStaleOn: null,
  entertainmentVideos: [],
  entertainmentVideosStale: null,
  entertainmentTranscriptions: [],
  entertainmentVocabularies: [],
  exerciseDetails: [],
  exerciseDetailsStaleOn: null,
  exerciseMasters: [],
  exerciseMastersStaleOn: null,
  serviceLogs: [],
  exerciseCategories,
  courseCategories,
  courseDifficultyLevel,
  exerciseOptionTypes,
  dropdowns: {},
  loading: false,
  users: [],
}

// Make a new auth reducer :ToDo
const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SIGN_IN_SUCCESSFUL:
      return {
        ...state,
        auth: action.payload,
      }
    case ActionTypes.SIGN_IN_FAILED:
      return {
        ...state,
        auth: null,
      }
    case ActionTypes.SIGN_OUT_SUCCESSFUL:
      return {
        ...state,
        auth: null,
      }
    case ActionTypes.SIGN_UP_SUCCESSFUL:
      return {
        ...state,
        auth: null,
      }
    case ActionTypes.USER_UPDATED:
      return {
        ...state,
        auth: action.payload,
      }
    case ActionTypes.SET_COURSES_STALE: {
      return {
        ...state,
        coursesStaleOn: Date.now(),
      }
    }
    case ActionTypes.SET_COURSES:
      return {
        ...state,
        courses: action.payload,
      }
    case ActionTypes.SET_USERS:
      return {
        ...state,
        users: action.payload,
      }
    case ActionTypes.SET_UNITS_STALE: {
      return {
        ...state,
        unitsStaleOn: Date.now(),
      }
    }
    case ActionTypes.SET_UNITS:
      return {
        ...state,
        units: action.payload,
      }
    case ActionTypes.SET_CHAPTERS:
      return {
        ...state,
        chapters: action.payload,
      }
    case ActionTypes.SET_CHAPTERS_STALE:
      return {
        ...state,
        chaptersStaleOn: Date.now(),
      }
    case ActionTypes.SET_REPORTS:
      return {
        ...state,
        reports: action.payload,
      }
    case ActionTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        reports: action.payload,
      }
    case ActionTypes.SET_FEEDBACKS:
      return {
        ...state,
        feedbacks: action.payload,
      }
    case ActionTypes.SET_LESSONS_STALE:
      return {
        ...state,
        lessonsStaleOn: Date.now(),
      }
    case ActionTypes.SET_LESSONS:
      return {
        ...state,
        lessons: action.payload,
        lessonsStale: false,
      }
    case ActionTypes.SET_BANNERS_STALE:
      return {
        ...state,
        bannersStaleOn: Date.now(),
      }
    case ActionTypes.SET_BANNERS:
      return {
        ...state,
        banners: action.payload,
        bannersStale: false,
      }
    case ActionTypes.SET_ENTERTAINMENT_VIDEOS_STALE:
      return {
        ...state,
        entertainmentVideosStale: Date.now(),
      }
    case ActionTypes.SET_ENTERTAINMENT_VIDEOS:
      return {
        ...state,
        entertainmentVideos: action.payload,
      }
    case ActionTypes.SET_ENTERTAINMENT_TRANSCRIPTIONS:
      return {
        ...state,
        entertainmentTranscriptions: action.payload,
      }
    case ActionTypes.SET_ENTERTAINMENT_VOCABULARIES:
      return {
        ...state,
        entertainmentVocabularies: action.payload,
      }
    case ActionTypes.SET_EXERCISE_DETAILS:
      return {
        ...state,
        exerciseDetails: action.payload,
      }

    case ActionTypes.SET_EXERCISE_DETAILS_STALE:
      return {
        ...state,
        exerciseDetailsStaleOn: Date.now(),
      }
    case ActionTypes.SET_EXERCISE_MASTERS:
      return {
        ...state,
        exerciseMasters: action.payload,
      }
    case ActionTypes.SET_EXERCISE_MASTERS_STALE:
      return {
        ...state,
        exerciseMastersStaleOn: Date.now(),
      }
    case ActionTypes.SET_SERVICE_LOGS:
      return {
        ...state,
        serviceLogs: action.payload,
      }
    case ActionTypes.SET_DROPDOWNS:
      return {
        ...state,
        dropdowns: action.payload,
      }
    case ActionTypes.SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      }
    case ActionTypes.SHOW_SPINNER:
      return {
        ...state,
        loading: true,
      }
    case ActionTypes.HIDE_SPINNER:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
export default combineReducers({ mainReducer, errorReducer })
