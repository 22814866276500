import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  FormControlLabel,
  FormGroup,
  IconButton,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import produce from 'immer'
import React, { useEffect, useRef,useState } from 'react'
import { useDispatch } from 'react-redux';
import { GetFourLData, GetTranslationTranslteration } from 'src/redux/actions';
import { transliterateUnicode } from 'src/util/helpers'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})


export default function SubtitleEditor({
  open,
  handleClose,
  onSave,
  subtitle,
}) {
  const [formattedData, setFormattedData] = useState(subtitle)
  const [selectedRow, setSelectedRow] = useState(null)
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const [textFieldValue, setTextFieldValue] = useState(''); 
  const [loading, setLoading] = useState(false);
  const [onlineSwitchChecked, setOnlineSwitchChecked] = useState(false);
  const abortControllerRef = useRef(null);
  const dispatch = useDispatch()

  useEffect(() => setFormattedData(subtitle), [subtitle]);

  useEffect(() => {
    if (selectedRow) setTextFieldValue(selectedRow.transcript[3] || '');
  }, [selectedRow]);

  useEffect(() => {
    if (selectedRow) {
      setTextFieldValue(selectedRow.transcript[3] || '');
    }
  }, [selectedRow]);

  // Effect to handle the online/offline switch toggle
  useEffect(() => {
    if (!onlineSwitchChecked && abortControllerRef.current) {
      // Abort the ongoing network request when offline mode is enabled
      abortControllerRef.current.abort();
      console.info('Aborted ongoing network request as offline mode was enabled');
    }
  }, [onlineSwitchChecked]);

  const transformFourLResponse = (response) => {
    return response.split(':')[1];
  }

  const getFourLData = async (text) => {
    const prompts = {
        translate: `Translate this '${text}' to english, output should be exact like 'translate: '`,
        transliterate: `Transliterate this '${text}' to roman, output should be exact like 'transliterate: '`,
        wordsTranslation: `Translate each word of this '${text} in english', output should be exact like 'wordsTranslation: word word, word, .... '`
    };

    try {
        const [translateRes, transliterationRes, wordTranslationsRes] = await Promise.all([
            dispatch(GetFourLData({ prompt: prompts.translate })),
            dispatch(GetFourLData({ prompt: prompts.transliterate })),
            dispatch(GetFourLData({ prompt: prompts.wordsTranslation }))
        ]);

        if (translateRes?.payload?.success) {
            const transformData = (response) => transformFourLResponse(response?.payload?.response);

            const [translateData, transliterationData, wordTranslationsResData] = await Promise.all([
                transformData(translateRes),
                transformData(transliterationRes),
                transformData(wordTranslationsRes)
            ]);

            return [translateData, transliterationData, wordTranslationsResData];
        }

        throw new Error(`Failed to fetch FourL data: ${translateRes.status}`);
    } catch (error) {
        console.error("Error during translation/transliteration:", error);
    }
};


  const onLineSelection = (item, index) => {
    setSelectedRow(item);
    index !== null && setSelectedRowIndex(index);
  };
  
  const moveNextPrev = (action) => {
    if (!formattedData || !formattedData.length) return;
  
    let newIndex = selectedRowIndex;
  
    if (action === 'next' && selectedRowIndex < formattedData.length - 1) {
      newIndex = selectedRowIndex + 1;
    } else if (action === 'prev' && selectedRowIndex > 0) {
      newIndex = selectedRowIndex - 1;
    }
  
    // Update the selected index and row
    setSelectedRowIndex(newIndex);
    setSelectedRow(formattedData[newIndex]);
    onLineSelection(formattedData[newIndex], newIndex);
  };

  const generate4L = async() => {
    try {
      let updatedTranscript = formattedData[selectedRowIndex]?.transcript || '';
      if(onlineSwitchChecked) {
      // Fetch translations in parallel
      setLoading(true); // Start loading
      const fourLResponse = await getFourLData(selectedRow.originalText);

      updatedTranscript = [
        fourLResponse[1].trim().split(' '),
        selectedRow.originalText.trim().split(' '),
        fourLResponse[2].trim().split(' '),
        fourLResponse[0],
      ];
    } else {
      updatedTranscript = [
        transliterateUnicode(selectedRow.originalText.trim()).split(' '),
        selectedRow.originalText.trim().split(' '),
        new Array(selectedRow.transcript[0].length).fill(''),
        ' '
      ];
    }
    const updatedItem = { ...selectedRow, transcript: updatedTranscript };

      const newFormattedData = [...formattedData];
      newFormattedData[selectedRowIndex] = updatedItem;
      setFormattedData(newFormattedData);
      setSelectedRow(updatedItem);

    } catch (error) {
      console.error("Error during translation:", error);
    } finally {
      setLoading(false);
    }
  }

  function onFileRead(event) {
    const content = event.target?.result?.toString()

    if (content) {
      const data = content.split(/\r?\n\r?\n/).filter(item => item && !!item.trim())
      transformData(data)
    }
  }


// Updated transformData function to process each rowData sequentially
async function transformData(rowData) {
  const tData = [];

  for (const value of rowData) {
    const [id, timePeriod, ...text] = value.split('\n');
    const [startTime, endTime] = timePeriod.split('-->');
    const words = text.join('\n').split(' ').filter(Boolean);
    const blankWords = new Array(words.length).fill('')
    const latinWords = transliterateUnicode(text.join('\n').trim()).split(' ');
   
    // Build row object
    tData.push({
      id,
      startTime: startTime.trim(),
      endTime: endTime.trim(),
      transcript: [latinWords, words, blankWords, '   '],
      originalText: text.join(''),
    });
  }
  
  // Update the state with formatted data
  setFormattedData(tData);
}

  function handleFileSelect(evt) {
    const files = evt.target.files // FileList object

    // use the 1st file from the list
    const f = files[0]

    const reader = new FileReader()

    // Closure to capture the file information.
    reader.onload = onFileRead

    // Read in the image file as a data URL.
    reader.readAsText(f)
  }

  function onCellEdit(val, row, col) {
    if (selectedRow) {
      const updatedRowData = produce(selectedRow, draft => {
        if(col !== undefined){
          draft.transcript[row][col] = val
        } else {
          draft.transcript[row] = val 
        }
        
      })

      const updatedFormattedData = formattedData.map(fd =>
        fd.id === selectedRow.id ? updatedRowData : fd
      )
      setSelectedRow(updatedRowData)
      setFormattedData(updatedFormattedData)
    }
  }

  const onResetRow = (rowIndex) => {
    console.log("rowIndex ==>", rowIndex)
    if(rowIndex == null) return ;
    let updatedItem = { ...selectedRow};
    updatedItem.transcript[rowIndex] = new Array(selectedRow.transcript[1]?.length).fill(" ");
    console.log("updatedItem ==>", updatedItem)
    setSelectedRow(updatedItem);
  }

  const onSaveClick = () => {
    onSave(formattedData)
  }


  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            SubtitleEditor
          </Typography>
          <Button autoFocus color="inherit" onClick={onSaveClick}>
            Save changes
          </Button>
        </Toolbar>
      </AppBar>
      <div>
        <Box display='flex' flexDirection='horizontal'>
          {!subtitle?.length && (
            <form>
              <input
                type="file"
                accept="text/plains"
                onChange={handleFileSelect}
              />
            </form>
          )}
          <FormGroup>
            <FormControlLabel control={
              <Switch 
                checked={onlineSwitchChecked}
                onChange={() => setOnlineSwitchChecked(!onlineSwitchChecked)}
                inputProps={{ 'aria-label': 'controlled' }} defaultChecked />} 
                label={`Online Translation/Transliteration ${onlineSwitchChecked ? 'ON' : 'OFF'}`} 
              />
          </FormGroup>
        </Box>
        
        {loading && <CircularProgress />}
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box borderBottom={1} minHeight={'50vh'} padding={2}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <FormRow
                transcript={selectedRow?.transcript[0]}
                onEdit={(val, col) => onCellEdit(val, 0, col)}
                onReset={() => onResetRow(0)}
              />
              <FormRow
                transcript={selectedRow?.transcript[1]}
                onEdit={(val, col) => onCellEdit(val, 1, col)}
                onReset={() => onResetRow(1)}
              />
              <FormRow
                transcript={selectedRow?.transcript[2]}
                onEdit={(val, col) => onCellEdit(val, 2, col)}
                onReset={() => onResetRow(2)}
              />
              <FormRow
                transcript={selectedRow?.transcript[3]}
                onEdit={(val) => onCellEdit(val, 3)}
                textFieldValue={textFieldValue}
                setTextFieldValue={setTextFieldValue}
              />
            </Box>
            <div style={{marginTop: '10px', display: 'flex', gap: '10px'}}>
              <Button onClick={() => moveNextPrev('prev')} disabled={selectedRowIndex <= 0 || !formattedData?.length}>Prev</Button>
              <Button onClick={() => moveNextPrev('next') } disabled={selectedRowIndex >= formattedData?.length - 1 || !formattedData?.length}>Next</Button>
              <Button onClick={() => generate4L() } disabled={selectedRow === null}>Generate</Button>
            </div>
          </Box>
          <Stack spacing={2} borderBottom={1} maxHeight={'250px'} overflow='auto'>
            {formattedData?.map((item, index) => (
              <Card
                sx={{ minWidth: 275, minHeight: 50, backgroundColor: selectedRow?.id === item?.id && 'lightblue' }}
                key={item.id}
                onClick={() => onLineSelection(item, index)}
              >
                <CardContent>
                  <Typography variant="h5" component="pre">
                    {item.originalText}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Stack>
        </Box>
      </div>
    </Dialog>
  )
}

function FormRow({ transcript, onEdit, textFieldValue, setTextFieldValue, onReset }) {
  if (!transcript) return null

  const handleOnBlur = (event, col) => {
    onEdit?.(event.currentTarget.value, col)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        borderRadius: 1,
      }}
    >
      {onReset && <Button size='small' onClick={onReset}>Reset</Button>}
      {Array.isArray(transcript) ? transcript.map((word, index) => (
        <Item sx={{ flex: 1 }} key={`${word}-${index}`}>
          <TextField
            fullWidth
            defaultValue={word}
            onBlur={e => handleOnBlur(e, index)}
          />
        </Item>
      )) : <TextField
            fullWidth
            value={textFieldValue}
            onChange={(e) => setTextFieldValue(e.target.value)}
            onBlur={e => handleOnBlur(e, 3)}
          />
      }
    </Box>
  )
}
