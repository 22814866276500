import { Button, Input, Box } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { getFilePreview } from 'src/util/helpers'

export default function VideoUploaderField({ onChange, isDisabled, src, fileKeys }) {
  const [videoSrc, setVideoSrc] = useState(src)

  useEffect(() => {
    if (src && !videoSrc) {
      setVideoSrc(src)
    }
  }, [src, videoSrc])

  const handleFileOnChange = event => {
    const file = event.target.files[0]

    if (file?.size > 5000000) {
      alert('Maximum file size can be 5MB')
      return
    }
    const blobURL = URL.createObjectURL(file)
    setVideoSrc(blobURL)
    onChange(event)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box>{videoSrc && getFilePreview(videoSrc, 'video')}</Box>
      <label htmlFor="contained-button-video-file">
        <Input
          key={fileKeys['exerciseVideo']}
          inputProps={{
            accept: 'video/*',
          }}
          id="contained-button-video-file"
          type="file"
          style={{ display: 'none' }}
          name="exerciseVideo"
          onChange={handleFileOnChange}
        />
        <Button variant="contained" component="span" disabled={isDisabled}>
          Upload Video
        </Button>
      </label>
    </Box>
  )
}
