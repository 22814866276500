import { Box, Container, Drawer } from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import AddButton from 'src/components/helperComponents/AddButton'
import useQuery from 'src/hooks/useQuery'
import useQueryTable from 'src/hooks/useQueryTable'
import { DeleteUnitById, GetUnits } from 'src/redux/actions'
import FreeTextSearchInput from 'src/ui/FreeTextSearchInput'
import { LocalTable } from 'src/ui/Table'
import { GetUnitTableColumns } from 'src/util/columns'
import { useLocation } from 'react-router'

import AddEditCourse from '../components/course/AddEditCourse'

const UnitList = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const query = useQuery()

  const [recordToEdit, setRecordToEdit] = useState()
  const { units } = useSelector(store => store.mainReducer)
  const [customFilterQuery, setCustomFilterQuery] = useState()

  const [searchText, setSearchText] = useState(null)

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })

  const { data, isLoading, isFetching, refetch } = useQueryTable({
    name: 'units',
    searchText: searchText,
    pageIndex: pageIndex,
    pageSize: pageSize,
    additionalFilters: customFilterQuery,
  })

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const lessonId = params.get('_id')

    if (lessonId) {
      setCustomFilterQuery(prev => ({
        ...prev,
        _id: lessonId,
      }))
    }
  }, [location.search])

  const onDeleteRow = async recordToDelete => {
    if (recordToDelete) {
      await dispatch(DeleteUnitById(recordToDelete))
      refetch()
    }
  }

  const columns = useMemo(() => GetUnitTableColumns(), [])

  return (
    <>
      <Helmet>
        <title>Course | Langistan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <AddButton
            dispatcher={GetUnits}
            type="Course"
            onClick={() => setRecordToEdit({})}
            setCustomFilterQuery={setCustomFilterQuery}
          />
          <Drawer
            anchor="right"
            open={!!recordToEdit}
            onClose={() => setRecordToEdit()}
            PaperProps={{
              sx: {
                width: 400,
                top: 64,
                height: 'calc(100% - 64px)',
              },
            }}
          >
            <AddEditCourse
              selectedRow={recordToEdit}
              toggleDrawer={() => setRecordToEdit()}
              refetch={refetch}
            />
          </Drawer>
          <Box sx={{ pt: 3 }}>
            {/* <DataTableNew
              data={units}
              columns={GetUnitTableColumns(setRecordToEdit, setRecordToDelete)}
              apiName="units"
              filters={query}
              customFilterQuery={customFilterQuery}
            /> */}
            <FreeTextSearchInput onChange={setSearchText} />
            <LocalTable
              columns={columns}
              onEditClick={setRecordToEdit}
              onDeleteClick={onDeleteRow}
              additionalFilters={customFilterQuery}
              data={data}
              name="units"
              isLoading={isLoading}
              isFetching={isFetching}
              setPagination={setPagination}
            />
          </Box>
          {/* {recordToDelete && (
            <DialogComponent
              title="Delete Course?"
              description="All related chapters, lessons, exercise masters will also be auto removed. Still want to delete?"
              onDialogOk={onDeleteRow}
              onDialogCancel={() => setRecordToDelete(!recordToDelete)}
            />
          )} */}
        </Container>
      </Box>
    </>
  )
}

export default UnitList
