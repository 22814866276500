import React from 'react'

import { ExcerciseTypes } from '../../util/constants'
import ListenAndTypeOrRecord from './FormTypes/ListenAndTypeOrRecord'
import RightAudioOption from './FormTypes/RightAudioOption'
import RightImageOption from './FormTypes/RightImageOption'
import RightOrderOfText from './FormTypes/RightOrderOfText'
import RightTextOption from './FormTypes/RightTextOption'
import WatchAndTypeOrRecord from './FormTypes/WatchAndTypeOrRecord'
import WatchVideo from './FormTypes/WatchVideo'
import { Box } from '@material-ui/core'

const QuestionTypeForm = ({
  type,
  onDataChange,
  onMediaRemove,
  childData = {},
  isDisabled,
  fileKeys={},
  questionTypeFormError
}) => {
  const renderBody = excerciseType => {
    switch (excerciseType) {
      case ExcerciseTypes.LISTEN_WITH_IMAGE:
        return (
          <RightImageOption
            childData={childData}
            onDataChange={onDataChange}
            onMediaRemove={onMediaRemove}
            fileKeys={fileKeys}
          />
        )
      case ExcerciseTypes.LISTEN_WITH_AUDIO:
        return (
          <RightAudioOption
            childData={childData}
            onDataChange={onDataChange}
            onMediaRemove={onMediaRemove}
            fileKeys={fileKeys}
          />
        )
      case ExcerciseTypes.LISTEN_WITH_ORDER:
        return (
          <RightOrderOfText
            childData={childData}
            onDataChange={onDataChange}
            onMediaRemove={onMediaRemove}
            fileKeys={fileKeys}
          />
        )
      case ExcerciseTypes.LISTEN_WITH_RECORD:
        return (
          <ListenAndTypeOrRecord
            childData={childData}
            onDataChange={onDataChange}
            onMediaRemove={onMediaRemove}
            fileKeys={fileKeys}
          />
        )
      case ExcerciseTypes.LISTEN_WITH_TEXT:
        return (
          <RightTextOption
            childData={childData}
            onDataChange={onDataChange}
            onMediaRemove={onMediaRemove}
            fileKeys={fileKeys}
          />
        )
      case ExcerciseTypes.LISTEN_WITH_TYPE:
        return (
          <ListenAndTypeOrRecord
            childData={childData}
            onDataChange={onDataChange}
            onMediaRemove={onMediaRemove}
            fileKeys={fileKeys}
          />
        )
      case ExcerciseTypes.WATCH_WITH_AUDIO:
        return (
          <RightAudioOption
            hasVideo
            childData={childData}
            onDataChange={onDataChange}
            onMediaRemove={onMediaRemove}
            fileKeys={fileKeys}
            isDisabled={isDisabled}
          />
        )
      case ExcerciseTypes.WATCH_4LVIDEO:
        return 'listen with audio'
      case ExcerciseTypes.WATCH_WITH_TYPE:
        return (
          <WatchAndTypeOrRecord
            childData={childData}
            onDataChange={onDataChange}
            onMediaRemove={onMediaRemove}
            fileKeys={fileKeys}
            isDisabled={isDisabled}
          />
        )
      case ExcerciseTypes.WATCH_WITH_TEXT:
        return (
          <RightTextOption
            hasVideo
            childData={childData}
            onDataChange={onDataChange}
            onMediaRemove={onMediaRemove}
            fileKeys={fileKeys}
            isDisabled={isDisabled}
          />
        )
      case ExcerciseTypes.WATCH_WITH_ORDER:
        return (
          <RightOrderOfText
            hasVideo
            childData={childData}
            onDataChange={onDataChange}
            onMediaRemove={onMediaRemove}
            fileKeys={fileKeys}
            isDisabled={isDisabled}
          />
        )
      case ExcerciseTypes.WATCH_VIDEO:
        return (
          <WatchVideo
            childData={childData}
            onDataChange={onDataChange}
            onMediaRemove={onMediaRemove}
            fileKeys={fileKeys}
          />
        )
      case ExcerciseTypes.WATCH_WITH_RECORD:
        return (
          <WatchAndTypeOrRecord
            childData={childData}
            onDataChange={onDataChange}
            onMediaRemove={onMediaRemove}
            fileKeys={fileKeys}
            isDisabled={isDisabled}
          />
        )
      default:
        return ''
    }
  }
  return <>
    <Box color='red'>{questionTypeFormError}</Box>
    {renderBody(type)}
  </>
}

export default QuestionTypeForm
