import { Box, Container, Drawer } from '@material-ui/core'
import { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import AddButton from 'src/components/helperComponents/AddButton'
import DialogComponent from 'src/components/helperComponents/DialogComponent'
import PlayerComponent from 'src/components/helperComponents/PlayerComponent'
import AddEditVideo from 'src/components/Videos/AddEditVideo'
import useQueryTable from 'src/hooks/useQueryTable'
import { DeleteVideoById } from 'src/redux/actions'
import FreeTextSearchInput from 'src/ui/FreeTextSearchInput'
import { LocalTable } from 'src/ui/Table'
import { GetLessonVideoTableColumns } from 'src/util/columns'

const EntertainmentVideoList = () => {
  const dispatch = useDispatch()

  const entertainmentVideos = useSelector(
    store => store.mainReducer.entertainmentVideos
  )

  const [recordToEdit, setRecordToEdit] = useState()
  const [recordToDelete, setRecordToDelete] = useState()
  const [mediaUrl, setMediaUrl] = useState()
  const [searchText, setSearchText] = useState(null)

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })

  // useEffect(() => {
  //   dispatch(GetEntertainmentVideos())
  // }, [])

  const onDeleteRow = () => {
    if (recordToDelete) {
      dispatch(DeleteVideoById(recordToDelete))
      setRecordToDelete()
    }
  }

  const columns = useMemo(() => GetLessonVideoTableColumns(setMediaUrl), [])

  const { data, isLoading, isFetching, refetch } = useQueryTable({
    name: 'videos',
    searchText: searchText,
    pageIndex: pageIndex,
    pageSize: pageSize,
  })


  return (
    <>
      <Helmet>
        <title>Entertainment Video | Langistan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <AddButton
            type="Entertainment Video"
            onClick={() => setRecordToEdit({})}
          />
          <Drawer
            anchor="right"
            open={!!recordToEdit}
            onClose={() => setRecordToEdit()}
            PaperProps={{
              sx: {
                width: 550,
                top: 64,
                height: 'calc(100% - 64px)',
              },
            }}
          >
            <AddEditVideo
              selectedRow={recordToEdit}
              toggleDrawer={() => setRecordToEdit()}
            />
          </Drawer>
          <Box sx={{ pt: 3 }}>
            <FreeTextSearchInput onChange={setSearchText} />
            <LocalTable
              columns={columns}
              onEditClick={setRecordToEdit}
              onDeleteClick={onDeleteRow}
              // additionalFilters={customFilterQuery}
              data={data}
              name="videos"
              isLoading={isLoading}
              isFetching={isFetching}
              setPagination={setPagination}
            />
          </Box>
          {mediaUrl && (
            <PlayerComponent
              url={mediaUrl}
              onDialogCancel={() => setMediaUrl()}
            />
          )}
          {recordToDelete && (
            <DialogComponent
              title="Delete Entertainment Video?"
              description="Deleted entertainment video can not be recovered. Still want to delete?"
              onDialogOk={onDeleteRow}
              onDialogCancel={() => setRecordToDelete(!recordToDelete)}
            />
          )}
        </Container>
      </Box>
    </>
  )
}

export default EntertainmentVideoList
