import { FormGroup, TextField, Stack, Box, Grid } from '@material-ui/core'
import { useEffect, useState } from 'react'
import VideoUploaderField from './Fields/VideoUploaderField'
// Note: This form covers 2 use cases "Watch and record" and "Watch and type"

const WatchVideo = ({ onDataChange, childData, isDisabled, fileKeys, }) => {
  const [values, setValues] = useState({})

  useEffect(() => {
    setValues(childData)
  }, [childData])

  const changeHandler = ({ target: { name, value, files } }) => {
    let finalValues = {}
    finalValues = {
      ...values,
      [name]: value,
    }
    if (files && files[0]) {
      finalValues = {
        ...values,
        exerciseVideo: files[0],
      }
    }

    setValues(finalValues)
    onDataChange(finalValues)
  }

  return (
    <Box sx={{ p: 2, border: '1px solid grey' }}>
      <FormGroup>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <VideoUploaderField
              onChange={changeHandler}
              isDisabled={isDisabled}
              src={values?.exerciseVideo}
              fileKeys={fileKeys}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Video Url"
              name={'video'}
              onChange={changeHandler}
              value={values.video}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </FormGroup>
    </Box>
  )
}

export default WatchVideo
