import { Autocomplete, CircularProgress,TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect,useState } from 'react';
import { fetchVideos } from 'src/api';

function AsyncAutoComplete({label, name, placeholder, value, onChange}) {
  const [options, setOptions] = useState([]);          // Options from the API
  const [loading, setLoading] = useState(false);        // Loading state for API requests
  let timeoutId; 

  const handleInputChange = async (event, inputValue) => {
    if (inputValue.length < 3) {
      setOptions([]);
      return;
    }
    clearTimeout(timeoutId);
    // Start fetching after 3 characters
     // Set a new timeout to delay the API call
    timeoutId = setTimeout(async () => {
      setLoading(true);
      try {
        const response = await fetchVideos({ searchText: inputValue });
        setOptions(response.docs); // Set options from API response
      } catch (error) {
        console.error("Error fetching options:", error);
      } finally {
        setLoading(false);
      }
    }, 300);
  };

  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Autocomplete
      options={options} // Set fetched options as Autocomplete options
      getOptionLabel={(option) => option.name || ''} // Customize option label (depends on API data structure)
      name={name}    
      onInputChange={handleInputChange}
      onChange={handleChange} // Set selected option
      loading={loading}
      isClearable
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      value={options.find((option) => option.id === value) || null}
      getOptionKey={(option) => option.id}
      getOptionSelected={(option, value) => option.id === value.id}
    />
  );
}

export default AsyncAutoComplete;
