import {
  Button,
  Input,
  FormControlLabel,
  FormGroup,
  TextField,
  Radio,
  Stack,
  Grid,
  Box,
  Divider,
} from '@material-ui/core'
import { getFilePreview } from 'src/util/helpers'
import { Fragment, useEffect, useState } from 'react'
import DeleteIconComponent from 'src/components/helperComponents/DeleteIconComponent'
// import VideoUploaderField from './Fields/VideoUploaderField'
import WatchVideoOrImageField from './Fields/WatchVideoOrImageField'

const RightAudioOption = ({
  numberOfRows = 3,
  hasVideo = false,
  onDataChange,
  onMediaRemove,
  childData,
  isDisabled,
  fileKeys={},
}) => {
  const [rows, addRows] = useState(numberOfRows)
  const [values, setValues] = useState({})
  useEffect(() => {
    setValues(childData)
  }, [childData])

  const changeHandler = (
    { target: { name, value, files, checked } },
    fieldLabel
  ) => {
    let finalValues
    if (files) {
      let file = files[0]
      file.fieldLabel = fieldLabel
      finalValues = {
        ...values,
        [name]: file,
      }
    } else {
      finalValues = {
        ...values,
        [name]: name === 'isImage' ? checked : value,
      }
    }
    setValues(finalValues)
    onDataChange(finalValues)
  }

  return (
    <Box sx={{ p: 2, border: '1px solid grey' }}>
      <FormGroup>
        {/* <VideoUploaderField
          onChange={e => changeHandler(e)}
          isDisabled={isDisabled}
          src={values.exerciseVideo}
        /> */}
        <WatchVideoOrImageField
          isImage={values.isImage}
          onChange={e => changeHandler(e)}
          isDisabled={isDisabled}
          value={values.isImage ? values.exerciseImage : values.exerciseVideo}
        />
        {hasVideo ? (
          <Stack direction="row" key="videoStack" spacing={2} margin={1}>
            <TextField
              fullWidth
              label="Video Url"
              name={'video'}
              onChange={changeHandler}
              required
              value={values.video}
              variant="outlined"
            />
          </Stack>
        ) : (
          <>
            <Stack direction="row" spacing={2} margin={1} key="audioStack">
              <Grid direction="row" container spacing={1}>
                <Grid item md={3} xs={3}>
                  <FormControlLabel
                    label={''}
                    htmlFor={`audio`}
                    control={
                      <>
                        <Input
                          key={fileKeys["audio"]}
                          style={{ display: 'none' }}
                          onChange={event => changeHandler(event, 'audio')}
                          name={`audio`}
                          inputProps={{
                            accept: 'audio/mp3',
                          }}
                          id={`audio`}
                          type="file"
                          disabled={!!values.audio}
                        />
                        <Button variant="contained" component="span">
                          {'Audio'}
                        </Button>
                      </>
                    }
                  ></FormControlLabel>
                </Grid>
                <Grid item md={8} xs={8}>
                  {getFilePreview(values[`audio`])}
                </Grid>
                <Grid item md={1} xs={1}>
                  {values[`audio`] && (
                    <DeleteIconComponent
                      onClick={() => onMediaRemove('audio', values)}
                    />
                  )}
                </Grid>
              </Grid>
            </Stack>
            <Divider key="audioDivider" />
            <Stack direction="row" spacing={2} margin={1} key="audioSlowStack">
              <Grid direction="row" container spacing={1}>
                <Grid item md={3} xs={3}>
                  <FormControlLabel
                    label={''}
                    htmlFor={`audioSlow`}
                    control={
                      <>
                        <Input
                          key={fileKeys["audioSlow"]}
                          style={{ display: 'none' }}
                          onChange={event => changeHandler(event, 'Slow_Audio')}
                          name={`audioSlow`}
                          inputProps={{
                            accept: 'audio/mp3',
                          }}
                          id={`audioSlow`}
                          type="file"
                          disabled={!!values.audioSlow}
                        />
                        <Button variant="contained" component="span">
                          {'Slow_Audio'}
                        </Button>
                      </>
                    }
                  ></FormControlLabel>
                </Grid>
                <Grid item md={8} xs={8}>
                  {getFilePreview(values[`audioSlow`])}
                </Grid>
                <Grid item md={1} xs={1}>
                  {values[`audioSlow`] && (
                    <DeleteIconComponent
                      onClick={() => onMediaRemove('audioSlow', values)}
                    />
                  )}
                </Grid>
              </Grid>
            </Stack>
            <Divider key="audioSlowDivider" />
          </>
        )}
        {[...Array(rows)].map((el, index) => (
          <Fragment key={index + 'Fragment'}>
            <Stack key={index + 'row'} direction="row" spacing={2} margin={1}>
              <Grid container spacing={1} direction="row">
                <Grid item md={3} xs={3}>
                  <FormControlLabel
                    label={''}
                    htmlFor={`option${index + 1}File`}
                    control={
                      <>
                        <Input
                          key={fileKeys[`option${index + 1}File`]}
                          style={{ display: 'none' }}
                          onChange={event =>
                            changeHandler(event, 'Audio' + (index + 1))
                          }
                          name={`option${index + 1}File`}
                          inputProps={{
                            accept: 'audio/mp3',
                          }}
                          id={`option${index + 1}File`}
                          type="file"
                          disabled={!!values[`option${index + 1}File`]}
                        />
                        <Button variant="contained" component="span">
                          {'Audio' + (index + 1)}
                        </Button>
                      </>
                    }
                  ></FormControlLabel>
                </Grid>
                <Grid item md={7} xs={7}>
                  {getFilePreview(values[`option${index + 1}File`])}
                </Grid>
                <Grid item md={1} xs={1}>
                  {values[`option${index + 1}File`] && (
                    <FormControlLabel
                      label={''}
                      control={
                        <Radio
                          name="answer"
                          onChange={changeHandler}
                          checked={values.answer === 'option' + (index + 1)}
                          value={'option' + (index + 1)}
                        />
                      }
                      htmlFor="answer"
                      disabled={!values[`option${index + 1}File`]}
                    ></FormControlLabel>
                  )}
                </Grid>
                <Grid item md={1} xs={1}>
                  {values[`option${index + 1}File`] && (
                    <DeleteIconComponent
                      onClick={() =>
                        onMediaRemove(`option${index + 1}File`, values)
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Stack>
            <Divider key={index + 'Divider'} />
          </Fragment>
        ))}
      </FormGroup>
    </Box>
  )
}

export default RightAudioOption
