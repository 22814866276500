import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import MuiAlert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { forwardRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddExerciseDetails } from 'src/redux/actions'
import { uploadToS3 } from 'src/util/uploadToS3'

import { StatusList } from '../../util/constants'
import DropdownMenu from '../helperComponents/DropdownMenu'
import LoadingButton from '../helperComponents/LoadingButton'
import QuestionTypeForm from './QuestionTypeForm'

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const getMappedData = selectedRow => {
  const childDataToEdit = {
    image: selectedRow.image,
    video: selectedRow.video,
    audio: selectedRow.audio,
    audioSlow: selectedRow.audioSlow,
    exerciseVideo: selectedRow.exerciseVideo,
    exerciseImage: selectedRow.exerciseImage,
    isImage: selectedRow.isImage,
    ...selectedRow.options,
  }
  if (selectedRow?.id) {
    childDataToEdit['_id'] = selectedRow.id
  }
  const formData = {
    id: selectedRow.id,
    questionType: selectedRow.questionType || 'LISTEN WITH IMAGE',
    category: selectedRow.category || 'Reading',
    name: selectedRow.name,
    question: selectedRow.question,
    coin: selectedRow.coin || 0,
    isActive:
      selectedRow && selectedRow.isActive === undefined
        ? true
        : selectedRow && selectedRow?.isActive,
  }
  return { childDataToEdit, formData }
}

const AddEditExerciseDetails = ({
  selectedRow = {},
  toggleDrawer,
  refetch,
}) => {
  const dispatch = useDispatch()
  const { exerciseOptionTypes, exerciseCategories } = useSelector(
    store => store.mainReducer
  )
  const { childDataToEdit, formData } = getMappedData(selectedRow)
  const [values, setValues] = useState(formData)
  const [childFormData, setChildFormData] = useState(childDataToEdit || {})
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState(undefined)
  const [questionTypeFormError, setQuestionTypeFormError] = useState('');
  const [fileKeys, setFileKeys] = useState({});

  useEffect(() => {
    const { childDataToEdit: childData, formData: newFormData } =
      getMappedData(selectedRow)
    setValues(newFormData)
    setChildFormData(childData)
  }, [selectedRow])

  const onSubmit = async event => {
    event.preventDefault()
    setQuestionTypeFormError("");
    try {
      setIsSaving(true)

      const reqData = { ...values, ...childFormData }
      if (!reqData.id) {
        delete reqData.id
      }
      if(!reqData?.answer){
        setQuestionTypeFormError("Please fill the data with answer");
        throw new Error("Fill the data sets properly");
      }
      // let formData = new FormData()
      for (let key in reqData) {
        if (reqData[key] instanceof File) {
          let file = reqData[key]
          await uploadToS3(file)
          reqData[key] = file.name
        } else {
          // reqData[key] = file.name
          // formData.append(key, reqData[key])
        }
      }
      toggleDrawer(false)
      await dispatch(AddExerciseDetails(reqData))
      refetch();
    } catch (error) {
      setError(error.message)
    } finally {
      setIsSaving(false)
    }
  }

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }
  const onQuestionTypeChange = event => {
    handleChange(event)
    setChildFormData()
  }

  const onMediaRemove = async (keyToRemove, childValues) => {
    let newData = { ...childValues }
    if (selectedRow?.id) {
      // if the file which is being removed
      // is actually the answer then remove the answer as well
      if (keyToRemove.replace('File', '') === newData.answer) {
        newData.answer = null
      }
    }
    newData[keyToRemove] = null
    setChildFormData(newData)

    // Update fileKeys to force re-render of the input field
    setFileKeys(prevKeys => ({
      ...prevKeys,
      [keyToRemove]: Date.now(), // Unique key to force re-render
    }));
  }

  const handleAlertClose = () => {
    setError(undefined)
  }

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Container maxWidth="md">
          <Typography color="textPrimary" variant="h4">
            Add Exercise Detail
          </Typography>
          <form autoComplete="off" onSubmit={onSubmit}>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  margin="normal"
                  name="name"
                  onChange={handleChange}
                  value={values?.name}
                  variant="outlined"
                  required
                  inputProps={{
                    minLength: 3,
                    maxLength: 75,
                  }}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <DropdownMenu
                    name="questionType"
                    label="Question Type"
                    value={values.questionType}
                    data={exerciseOptionTypes}
                    onChange={onQuestionTypeChange}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <DropdownMenu
                    name="category"
                    label="Category"
                    value={values?.category}
                    data={exerciseCategories}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                  <DropdownMenu
                    value={values.isActive}
                    label="Status"
                    name="isActive"
                    defaultValue={'true'}
                    isRequired
                    onChange={handleChange}
                    data={StatusList}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Question"
                  margin="normal"
                  name="question"
                  onChange={handleChange}
                  value={values?.question}
                  variant="outlined"
                  required
                  inputProps={{
                    minLength: 5,
                  }}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Coin"
                  margin="normal"
                  name="coin"
                  type="number"
                  // defaultValue={0}
                  onChange={handleChange}
                  value={values?.coin || 0}
                  variant="outlined"
                />
              </Grid>
              {values.questionType && (
                <Grid item md={12} xs={12}>
                  <QuestionTypeForm
                    onDataChange={childFormValues =>
                      setChildFormData(childFormValues)
                    }
                    onMediaRemove={onMediaRemove}
                    childData={childFormData}
                    questionTypeFormError={questionTypeFormError}
                    type={values?.questionType}
                    isDisabled={isSaving}
                    fileKeys={fileKeys}
                  />
                </Grid>
              )}
              <Grid item md={6} xs={12}>
                <LoadingButton
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  disabled={isSaving}
                  name="Save"
                ></LoadingButton>
              </Grid>
              <Grid item md={6} xs={12}>
                <Button
                  color="primary"
                  onClick={() => toggleDrawer(false)}
                  fullWidth
                  size="large"
                  type="button"
                  variant="contained"
                  disabled={isSaving}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Box>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  )
}

export default AddEditExerciseDetails