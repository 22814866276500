import { TextField } from '@material-ui/core'

import { API_URI } from './config'

const allowedFileNames = [
  'audio',
  'slowaudio',
  'option1',
  'option2',
  'option3',
  'option4',
  'option5',
  'option6',
  'option7',
  'option8',
  'option9',
  'option10',
  'option1File',
  'option2File',
  'option3File',
  'option4File',
  'option5File',
  'option6File',
  'option7File',
  'option8File',
  'option9File',
  'option10File',
]

export const LOADING_ERROR = asset =>
  `Error during loading ${asset}, Please try again.`
export const ADD_ERROR = asset =>
  `Error during adding/updating ${asset}, Please try again.`
export const GET_ERROR = asset =>
  `Error during fetching ${asset}, Please try again.`
export const DELETE_ERROR = asset =>
  `Error during deleting ${asset}, Please try again.`

export const getFiles = values => {
  let filesToReturn = []
  for (let value in values) {
    if (typeof values[value] === 'object' && allowedFileNames.includes(value)) {
      filesToReturn.push(values[value])
    }
  }
  return filesToReturn
}

export const isImage = file =>
  typeof file === 'string' && file.match(/\.(png|jpg|jpeg|svg|gif)$/i)
export const isAudio = file =>
  typeof file === 'string' && file.match(/\.(mp3|ogv)$/)
export const isVideo = file =>
  typeof file === 'string' && file.match(/\.(mp4|mpeg-4|avi|mov|3gp)$/)
export const isFile = file =>
  typeof file === 'string' && file.match(/\.(zip)$/)

const getFileUrl = fileName => {
  return `https://cdn.langistan.com/media/${fileName}`
  // return `${API_URI}download/${file}`
}

export const getFilePreview = (file, fileType) => {
  let fileToReturn = null

  if (file?.type?.includes('image') || (file && isImage(file))) {
    fileToReturn = (
      <img
        alt=""
        src={isImage(file) ? getFileUrl(file) : URL.createObjectURL(file)}
        height={100}
        width={200}
      />
    )
  } else if (file?.type?.includes('audio') || (file && isAudio(file))) {
    fileToReturn = (
      <audio controls>
        <source
          src={isAudio(file) ? getFileUrl(file) : URL.createObjectURL(file)}
          type={file.type}
        />
        Your browser does not support the audio element.
      </audio>
    )
  } else if (file && fileType === 'video') {
    fileToReturn = (
      <video
        width="320"
        height="240"
        controls
        src={isVideo(file) ? getFileUrl(file) : file}
      >
        Your browser does not support the video tag.
      </video>
    )
  } else if ((file && file?.type?.includes('zip')) || (file && isFile(file))) {
    fileToReturn = (
      <p>{file?.name || file}</p>
    )
  }
  return fileToReturn
}

export const sortedByOrder = data => {
  return data.sort(
    (firstEl, secondEl) => firstEl?.sortOrder - secondEl?.sortOrder
  )
}

export const filterByKeyValue = (list = [], key, value) => {
  if (list && key && value) {
    return list.filter(item => item[key] === value)
  }
  return list
}

//This check is only for the old data which was come from migration.
// Right now we don't support lessons which has more than one Video
export const getLessonsWithLessThanTwoVideos = lessons => {
  if (lessons) {
    return lessons.filter(
      lesson => lesson.videos?.length === 0 || lesson.videos?.length === 1
    )
  }
  return lessons
}

export const returnTime = time => {
  let items = []
  for (let i = 0; i <= time; i++) {
    items.push({ key: i, value: i })
  }
  return items
}
export const minutes = returnTime(59)
export const hrs = returnTime(50)

// Define a mapping of Unicode values to Latin characters, including matras and special cases
const unicodeToLatinMap = {
  0x0915: "ka", 0x0916: "kh", 0x0917: "g", 0x0918: "gh", 0x091A: "ch", 0x091B: "chh",
  0x091C: "j", 0x091D: "jh", 0x091F: "t", 0x0920: "th", 0x0921: "d", 0x0922: "dh",
  0x0924: "t", 0x0925: "th", 0x0926: "d", 0x0927: "dh", 0x0928: "n", 0x092A: "p",
  0x092B: "ph", 0x092C: "b", 0x092D: "bh", 0x092E: "m", 0x092F: "y", 0x0930: "r",
  0x0932: "l", 0x0935: "v", 0x0936: "sh", 0x0937: "sh", 0x0938: "s", 0x0939: "h",
  0x0905: "a", 0x0906: "aa", 0x0907: "i", 0x0908: "ee", 0x0909: "u", 0x090A: "oo",
  0x090F: "e", 0x0910: "ai", 0x0913: "o", 0x0914: "au", 0x0902: "n", 0x0901: "n",
  0x0903: "h", 0x093E: "a", 0x093F: "i", 0x0940: "ee", 0x0941: "u", 0x0942: "oo",
  0x0947: "e", 0x0948: "ai", 0x094B: "o", 0x094C: "au", 0x094D: "" // Halant (virama)
};

// Function to transliterate the text
export function transliterateUnicode(text) {
  let result = '';
  let skipNext = false;

  for (let i = 0; i < text.length; i++) {
    // Skip the character if it's part of a compound that was already processed
    if (skipNext) {
      skipNext = false;
      continue;
    }

    let unicodeValue = text.charCodeAt(i);

    // Handle compound characters like त्र (tr) and ज्ञ (gy)
    if (unicodeValue === 0x0924 && text.charCodeAt(i + 1) === 0x094D && text.charCodeAt(i + 2) === 0x0930) {
      result += "tr";
      skipNext = true;
      i += 2; // Skip the next two characters
      continue;
    }
    if (unicodeValue === 0x091C && text.charCodeAt(i + 1) === 0x094D && text.charCodeAt(i + 2) === 0x091E) {
      result += "gy";
      skipNext = true;
      i += 2; // Skip the next two characters
      continue;
    }

    // Process consonant + matra combination
    if (unicodeToLatinMap[unicodeValue]) {
      // Check if the next character is a matra
      if (i + 1 < text.length && unicodeToLatinMap[text.charCodeAt(i + 1)]) {
        result += unicodeToLatinMap[unicodeValue] + unicodeToLatinMap[text.charCodeAt(i + 1)];
        i++; // Skip the matra as it is already combined
      } else if (i + 1 < text.length && text.charCodeAt(i + 1) === 0x094D) {
        // If next character is a halant (virama), skip it without adding "a"
        result += unicodeToLatinMap[unicodeValue];
        i++; // Skip the halant
      } else {
        result += unicodeToLatinMap[unicodeValue];
      }
    } else {
      // If no mapping found, keep the original character (e.g., spaces)
      result += text[i];
    }
  }

  return result;
}