import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  Stack,
  TextField,
} from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteIcon from '@material-ui/icons/Delete'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import DeleteIconComponent from 'src/components/helperComponents/DeleteIconComponent'
import { setError } from 'src/redux/errorReducer'
import { getFilePreview } from 'src/util/helpers'
// import VideoUploaderField from './Fields/VideoUploaderField'
import WatchVideoOrImageField from './Fields/WatchVideoOrImageField'

// Note: This form covers 2 use cases "Listen and choose right order of text"
// and "Watch and choose right order of text"

const getOptionKeys = obj =>
  Object.keys(obj).filter(
    key =>
      key.indexOf('option') > -1 &&
      key !== 'optiontype' &&
      key.toLowerCase().indexOf('nativeoption') === -1
  )

const getIntialOptions = childData => {
  let totalOptions = getOptionKeys(childData).length
  totalOptions = totalOptions === 0 ? 3 : totalOptions
  return [...new Array(totalOptions)].map((_, index) => ({
    key: `option${index + 1}`,
    value: childData[`option${index + 1}`],
    nativeKey: `nativeOption${index + 1}`,
    nativeValue: childData[`nativeOption${index + 1}`],
  }))
}

const RightOrderOfText = ({
  hasVideo = false,
  onDataChange,
  onMediaRemove,
  childData,
  isDisabled,
  fileKeys={},
}) => {
  const [options, setOptions] = useState(getIntialOptions(childData))
  const [values, setValues] = useState(childData)

  const dispatch = useDispatch()

  useEffect(() => {
    setValues(childData)
    setOptions(getIntialOptions(childData))
  }, [childData])

  const updateOption = newOption => {
    const newData = { ...childData }
    const oldOptions = getOptionKeys(newData)
    oldOptions.forEach((_, index) => {
      delete newData[`option${index + 1}`]
      delete newData[`nativeOption${index + 1}`]
    })

    newOption.forEach(option => {
      newData[option.key] = option.value
      newData[option.nativeKey] = option.nativeValue
    })
    return newData
  }

  const changeHandler = (
    { target: { name, value, files, checked } },
    fieldLabel
  ) => {
    let finalValues
    if (files?.length) {
      let file = files[0]
      file.fieldLabel = fieldLabel
      finalValues = {
        ...values,
        [name]: file,
      }
    } else {
      finalValues = {
        ...values,
        [name]: name === 'isImage' ? checked : value,
      }
    }

    onDataChange(finalValues)
  }

  const onAddButtonClick = () => {
    const newOptionIndex = options.length + 1
    const newData = updateOption([
      ...options,
      {
        key: `option${newOptionIndex}`,
        value: '',
        nativeKey: `nativeOption${newOptionIndex}`,
        nativeValue: '',
      },
    ])
    onDataChange(newData)
  }

  const onRemoveButtonClick = currentIndex => {
    if (options.length === 3) {
      dispatch(setError('Options must not be less than 3.'))
      return
    }
    const filteredData = [...options].filter(
      (option, index) => index !== currentIndex
    )
    const newData = updateOption(
      filteredData.map((option, index) => ({
        key: `option${index + 1}`,
        value: option.value,
        nativeKey: `nativeOption${index + 1}`,
        nativeValue: option.nativeValue,
      }))
    )
    onDataChange(newData)
  }

  return (
    <Box sx={{ p: 2, border: '1px solid grey' }} className='right-order-text-container'>
      <FormGroup>
        {/* <VideoUploaderField
          onChange={e => changeHandler(e)}
          isDisabled={isDisabled}
          src={values.exerciseVideo}
        /> */}
        <WatchVideoOrImageField
          isImage={values.isImage}
          onChange={e => changeHandler(e)}
          isDisabled={isDisabled}
          value={values.isImage ? values.exerciseImage : values.exerciseVideo}
          fileKeys={fileKeys}
        />
        {hasVideo ? (
          <Stack direction="row" spacing={2} margin={1}>
            <TextField
              fullWidth
              label="Video Url"
              name={'video'}
              onChange={changeHandler}
              value={values?.video}
              variant="outlined"
            />
          </Stack>
        ) : (
          <Fragment>
            <Stack direction="row" spacing={2} margin={1}>
              <Grid direction="row" container spacing={1}>
                <Grid item md={3} xs={3}>
                  <FormControlLabel
                    label={''}
                    htmlFor={`audio`}
                    control={
                      <>
                        <Input
                          key={fileKeys['audio']}
                          style={{ display: 'none' }}
                          onChange={event => changeHandler(event, 'Audio')}
                          name={`audio`}
                          inputProps={{
                            accept: 'audio/mp3',
                          }}
                          id={`audio`}
                          type="file"
                          // disabled={!!values?.audio}
                        />
                        <Button variant="contained" component="span">
                          {'Audio'}
                        </Button>
                      </>
                    }
                  ></FormControlLabel>
                </Grid>
                <Grid item md={8} xs={8}>
                  {getFilePreview(values[`audio`])}
                </Grid>
                <Grid item md={1} xs={1}>
                  {values[`audio`] && (
                    <DeleteIconComponent
                      onClick={() => onMediaRemove('audio', values[`audio`])}
                    />
                  )}
                </Grid>
              </Grid>
            </Stack>
            <Divider key="audioDivider" />
            <Stack direction="row" spacing={2} margin={1}>
              <Grid direction="row" container spacing={1}>
                <Grid item md={3} xs={3}>
                  <FormControlLabel
                    label={''}
                    htmlFor={`audioSlow`}
                    control={
                      <>
                        <Input
                          key={fileKeys['audioSlow']}
                          style={{ display: 'none' }}
                          onChange={event => changeHandler(event, 'Slow_Audio')}
                          name={`audioSlow`}
                          inputProps={{
                            accept: 'audio/mp3',
                          }}
                          id={`audioSlow`}
                          type="file"
                          // disabled={!!values?.audioSlow}
                        />
                        <Button variant="contained" component="span">
                          {'Slow_Audio'}
                        </Button>
                      </>
                    }
                  ></FormControlLabel>
                </Grid>
                <Grid item md={8} xs={8}>
                  {getFilePreview(values[`audioSlow`])}
                </Grid>
                <Grid item md={1} xs={1}>
                  {values[`audioSlow`] && (
                    <DeleteIconComponent
                      onClick={() => onMediaRemove('audioSlow', values)}
                    />
                  )}
                </Grid>
              </Grid>
            </Stack>
            <Divider key="slowAudioDivider" />
          </Fragment>
        )}
        <Stack direction="row" spacing={2} margin={1}>
          <TextField
            fullWidth
            label="Answer"
            name={'answer'}
            onChange={changeHandler}
            required
            value={values?.answer}
            variant="outlined"
          />
        </Stack>
        {options.map((option, index) => (
          <Fragment key={index + 'Fragement'}>
            <Stack
              key={index + 'row'}
              direction="column"
              spacing={2}
              margin={1}
            >
              <Grid direction="row" container spacing={1}>
                <Grid item md={3} xs={3}>
                  <FormControlLabel
                    label={'Option' + (index + 1)}
                    control={<></>}
                    htmlFor="answer"
                  ></FormControlLabel>
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField
                    fullWidth
                    label="Words"
                    name={option.key}
                    onChange={changeHandler}
                    required
                    value={option.value || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={4}>
                  <TextField
                    fullWidth
                    label="Native Words"
                    name={option.nativeKey}
                    onChange={changeHandler}
                    value={option.nativeValue || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={1} xs={1}>
                  <IconButton
                    size="large"
                    onClick={() => onRemoveButtonClick(index)}
                  >
                    <DeleteIcon fontSize="medium" />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid direction="row" container spacing={1}>
                <Grid item md={4} xs={4}>
                  <FormControlLabel
                    label={''}
                    htmlFor={`option${index + 1}File`}
                    control={
                      <>
                        <Input
                          key={fileKeys[`option${index + 1}File`]}
                          style={{ display: 'none' }}
                          onChange={event =>
                            changeHandler(event, 'Audio' + (index + 1))
                          }
                          name={`option${index + 1}File`}
                          inputProps={{
                            accept: 'audio/mp3',
                          }}
                          id={`option${index + 1}File`}
                          type="file"
                          // disabled={!!values && values[`option${index + 1}File`]}
                        />
                        <Button variant="contained" component="span">
                          {'Audio' + (index + 1)}
                        </Button>
                      </>
                    }
                  ></FormControlLabel>
                </Grid>
                <Grid item md={7} xs={7}>
                  {getFilePreview(values[`option${index + 1}File`])}
                </Grid>
                <Grid item md={1} xs={1}>
                  {values[`option${index + 1}File`] && (
                    <DeleteIconComponent
                      onClick={() =>
                        onMediaRemove(`option${index + 1}File`, values)
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Stack>
            <Divider key={index + 'Divider'} />
          </Fragment>
        ))}
        <Grid direction="row" container spacing={1} justifyContent={'flex-end'}>
          <Grid item>
            <IconButton size="large" onClick={onAddButtonClick}>
              <AddBoxIcon fontSize={'large'} />
            </IconButton>
          </Grid>
        </Grid>
      </FormGroup>
    </Box>
  )
}

export default RightOrderOfText
