import { useQuery } from '@tanstack/react-query'
import omitBy from 'lodash/omitBy'
import React from 'react'
import { createListFetcher } from 'src/api'

export default function useQueryTable({
  name,
  searchText,
  additionalFilters,
  pageSize,
  pageIndex,
}) {
  const fetchDataOptions = omitBy(
    {
      pageIndex,
      pageSize,
      searchText,
      additionalFilters: additionalFilters ? additionalFilters : null,
    },
    val => val?.toString()?.trim() === '' || val === null || val === undefined
  )
  const { data, isLoading, isFetching, refetch } = useQuery(
    [name, fetchDataOptions],
    createListFetcher(name),
    { keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )

  return { data, isLoading, isFetching, refetch }
}
