import { Box, Button, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 450,
})

const DraggableList = ({ data, title, onDataOrderChange }) => {
  const [items, setItems] = useState([])

  useEffect(() => {
    setItems(data)
  }, [data])

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const orderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    )

    setItems(orderedItems)

    if (onDataOrderChange) {
      onDataOrderChange(orderedItems)
    }
    console.log('items after reorder -->', orderedItems)
  }
  const onRemoveItems = selectedItem => {
    if (selectedItem && selectedItem._id) {
      const index = data.findIndex(itx => itx._id === selectedItem._id)
      if (index !== -1) {
        data.splice(index, 1)
        const orderedItems = reorder(data, index, data.length)
        setItems(orderedItems)
        onDataOrderChange(orderedItems)
      }
    }
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {title && (
        <Typography color="textPrimary" variant="h4">
          Reorder Exercise details
        </Typography>
      )}
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items?.map((item, index) => {
              return (
                item && (
                  <Box key={item?._id}>
                    <Draggable
                      key={item?._id}
                      draggableId={item?._id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <span>
                            {item.name}{' '}
                            <Button
                              style={{
                                color: '#fff',
                              }}
                              onClick={() => {
                                onRemoveItems(item)
                              }}
                            >
                              Remove
                            </Button>
                          </span>
                          <br/>
                          Question:{' '}
                          <span id={`${item?._id}question`}>
                            {item?.question}
                          </span>
                          <br/>
                          Question Type: {item?.questionType}
                          <p>Status: {item?.isActive?.toString()}</p>
                        </div>
                      )}
                    </Draggable>
                    <span 
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={() =>
                        navigator.clipboard.writeText(
                          document.getElementById(`${item?._id}question`)
                            .innerText
                        )
                      }
                    >
                      Copy question
                    </span>
                  </Box>
                )
              )
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

DraggableList.propTypes = {
  data: PropTypes.array,
}

export default DraggableList
