import { Box, Button, Input } from '@material-ui/core'
import { useState } from 'react'
import { getFilePreview } from 'src/util/helpers'

function FileUpload({
  fileName,
  onChange,
  label = 'Upload a zip file',
  id = 'contained-button-zip-file',
}) {
  const [file, setImageFile] = useState('')

  const handleFileOnChange = event => {
    const file = event.target.files[0]
    if (file.size > 2500000) {
      alert('Maximum file size can be 25MB')
      return
    }
    setImageFile(file)
    onChange(event)
  }
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box>
        {(file || fileName) && getFilePreview(file || fileName)}
      </Box>
      <label htmlFor={id}>
        <Input
          inputProps={{
            accept: '.zip',
          }}
          id={id}
          type="file"
          style={{ display: 'none' }}
          name="text-file"
          onChange={handleFileOnChange}
        />
        <Button variant="contained" component="span">
          {label}
        </Button>
      </label>
    </Box>
  )
}

export default FileUpload
