import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
} from '@material-ui/core'
import InputIcon from '@material-ui/icons/Input'
import MenuIcon from '@material-ui/icons/Menu'
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { userSignOut } from 'src/redux/actions'
import API from 'src/util/api'

import Logo from './Logo'

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const [notifications] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // const onLogoutClick = async () => {
  //   try {
  //     await auth.logout()
  //   } catch (err) {
  //     console.log(err)
  //   } finally {
  //     history.push('/login')
  //     // @todo: Need to fix window reloading
  //     window.location.reload(false)
  //   }
  // }

  async function logout() {
    return API.get('logout').then(data => {
      dispatch(userSignOut())
    })
  }
  const handleLogout = () => {
    try {
      logout()
    } catch (err) {
      console.log(err)
    } finally {
      navigate('/login')
      // @todo: Need to fix window reloading
      window.location.reload(false)
    }
  }

  const refreshToken = () => {
    API.post('/refreshToken')
  }

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <Logo onClick={refreshToken} />
        <Box sx={{ flexGrow: 1 }} />
        <Hidden mdDown>
          <IconButton color="inherit" size="large">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit" size="large" onClick={handleLogout}>
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
}

export default DashboardNavbar
