import { Box, Container, Drawer } from '@material-ui/core'
import { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddEditExerciseDetails from 'src/components/exercise/AddExcerciseDetails'
import AddButton from 'src/components/helperComponents/AddButton'

import FreeTextSearchInput from 'src/ui/FreeTextSearchInput'
import { LocalTable } from 'src/ui/Table'
import useQueryTable from 'src/hooks/useQueryTable'
import {
  DeleteExerciseMasterDetailById,
  GetExerciseDetails,
} from 'src/redux/actions'
import { GetExerciseDetailTableColumns } from 'src/util/columns'
import { useLocation } from 'react-router'

const ExerciseDetailList = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [recordToEdit, setRecordToEdit] = useState()
  const [customFilterQuery, setCustomFilterQuery] = useState()
  const [searchText, setSearchText] = useState(null)

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })


  const { data, isLoading, isFetching, refetch } = useQueryTable({
      name: 'exerciseDetails',
      searchText: searchText,
      pageIndex: pageIndex,
      pageSize: pageSize,
      additionalFilters: customFilterQuery,
    })

    useEffect(() => {
      const params = new URLSearchParams(location.search)
      const exerciseId = params.get('_id')
  
      if (exerciseId) {
        setCustomFilterQuery(prev => ({
          ...prev,
          _id: exerciseId,
        }))
      }
    }, [location.search])

  const onDeleteRow = async recordToDelete => {
      if (recordToDelete) {
        await dispatch(DeleteExerciseMasterDetailById(recordToDelete))
        refetch()
      }
  }

  const columns = useMemo(() => GetExerciseDetailTableColumns(), [])

  return (
    <>
      <title>Exercise Details | Langistan</title>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <AddButton
            type="Exercise Detail"
            onClick={() => setRecordToEdit({})}
            dispatcher={GetExerciseDetails}
            setCustomFilterQuery={setCustomFilterQuery}
          />
          <Drawer
            anchor="right"
            open={!!recordToEdit}
            onClose={() => setRecordToEdit()}
            PaperProps={{
              sx: {
                width: 700,
                top: 64,
                height: 'calc(100% - 64px)',
              },
            }}
          >
            <AddEditExerciseDetails
              selectedRow={recordToEdit}
              toggleDrawer={() => setRecordToEdit()}
              refetch={refetch}
            />
          </Drawer>
          <Box sx={{ pt: 3 }}>
            <FreeTextSearchInput onChange={setSearchText} />
            <LocalTable
              columns={columns}
              onEditClick={setRecordToEdit}
              onDeleteClick={onDeleteRow}
              additionalFilters={customFilterQuery}
              data={data}
              name="exerciseDetails"
              isLoading={isLoading}
              isFetching={isFetching}
              setPagination={setPagination}
            />
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default ExerciseDetailList
