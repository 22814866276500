import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core'
import moment from 'moment'
import useUser from 'src/hooks/useUser'

const AccountProfile = props => {
  const { data: user } = useUser()
  return (
    <Card {...props}>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Avatar
            src={user?.image ?? '/static/images/avatars/avatar_5.png'}
            sx={{
              height: 100,
              width: 100,
            }}
          />
          <Typography color="textPrimary" gutterBottom variant="h3">
            {user?.firstName ? user.firstName : user?.username}
            {user?.lastName && user.lastName}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {`${user?.city} ${user?.country}`}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {`${moment().format('DD/MM/YYYY hh:mm A')} `}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button color="primary" fullWidth variant="text">
          Upload picture
        </Button>
      </CardActions>
    </Card>
  )
}

export default AccountProfile
