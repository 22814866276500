import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Link,
  TextField,
  Typography,
  IconButton, InputAdornment
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Formik } from 'formik'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import useToken from 'src/hooks/useToken'
import { SignInSuccessful } from 'src/redux/actions'
import { setError } from 'src/redux/errorReducer'
import API from 'src/util/api'
// import swal from 'sweetalert'
import * as Yup from 'yup'

const Login = () => {
  const [_token, setToken] = useToken()
  const [open, setOpen] = useState(false)
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleDialog = condition =>
    condition === 'open' ? setOpen(true) : setOpen(false)

  const dispatch = useDispatch()

  const onSubmit = async loginInfo => {
    try {
      const response = await API.post('newlogin', loginInfo)
      if (response?.success) {
        setToken(response?.token)
        dispatch(SignInSuccessful(response))
      } else {
        handleDialog('open')
      }
    } catch (error) {
      dispatch(setError(error))
    }

    // dispatch(SignIn(values))
  }

  return (
    <>
      <Helmet>
        <title>Login | Langistan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
              password: Yup.string().max(255).required('Password is required'),
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  value={values.password}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
        <Dialog
          open={open}
          onClose={() => handleDialog('close')}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Typography variant="h5">Invalid credentials</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDialog('close')}>Okay</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  )
}

export default Login
