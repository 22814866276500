import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import uniqBy from 'lodash/uniqBy'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDropdowns from 'src/hooks/useDropdowns'
import useQueryTable from 'src/hooks/useQueryTable'
import {
  AddExcerciseMaster,
  GetChapters,
  GetCourses,
  GetExerciseDetails,
  GetLessons,
  GetUnits,
} from 'src/redux/actions'
import { LocalTable } from 'src/ui/Table'
import { GetExerciseDetailSubSetColumns } from 'src/util/columns'
import { filterByKeyValue } from 'src/util/helpers'
import * as Yup from 'yup'

import { StatusList } from '../../util/constants'
import DraggableList from '../helperComponents/DraggableList'
import DropdownMenu from '../helperComponents/DropdownMenu'

const AddEditExcerciseMaster = ({
  selectedRow = {},
  toggleDrawer,
  refetch,
}) => {
  const initialValues = {
    name: selectedRow.name,
    lesson: selectedRow.lesson?._id,
    course: selectedRow.course?._id,
    chapter: selectedRow.chapter?._id,
    unit: selectedRow.unit?._id,
    exerciseDetails: selectedRow.exerciseDetails,
    id: selectedRow.id,
    isActive:
      selectedRow && selectedRow.isActive === undefined
        ? true
        : selectedRow && selectedRow?.isActive,
  }

  const preselectedRows = (selectedRow?.exerciseDetails || []).map(item => ({
    ...item,
    id: item._id,
  }))

  const dispatch = useDispatch()
  const exerciseDetails = useSelector(
    store => store.mainReducer.exerciseDetails
  )

  const { dropdowns, loading } = useDropdowns()

  const [selectedRows, setSelectedRows] = useState(preselectedRows)
  const [reorderedRows, setReorderedRows] = useState(preselectedRows)
  const [totalUnits, setTotalUnits] = useState([])
  const [totalChapters, setTotalChapters] = useState([])
  const [totalLessons, setTotalLessons] = useState([])
  const [searchText, setSearchText] = useState(null)

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })

  const { data, isLoading, isFetching } = useQueryTable({
    name: 'exerciseDetails',
    searchText: searchText,
    pageIndex: pageIndex,
    pageSize: pageSize,
  })

  useEffect(() => {
    setTotalUnits(dropdowns?.units)
    setTotalChapters(dropdowns?.chapters)
    setTotalLessons(dropdowns?.lessons)
  }, [dropdowns])

  const onCourseChange = useCallback(({ target: { value } }, setFieldValue) => {
    setTotalUnits(filterByKeyValue(dropdowns?.units, 'course', value))
    setTotalChapters([])
    setTotalLessons([])
    setFieldValue('chapter', '')
    setFieldValue('unit', '')
    setFieldValue('lesson', '')
  }, [])

  const onUnitChange = useCallback(({ target: { value } }, setFieldValue) => {
    setTotalChapters(filterByKeyValue(dropdowns?.chapters, 'unit', value))
    setTotalLessons([])
    setFieldValue('chapter', '')
    setFieldValue('lesson', '')
  }, [])

  const onChapterChange = useCallback(
    ({ target: { value } }, setFieldValue) => {
      setTotalLessons(filterByKeyValue(dropdowns?.lessons, 'chapter', value))
      setFieldValue('lesson', '')
    },
    []
  )

  useEffect(() => {
    dispatch(GetChapters())
    dispatch(GetUnits())
    dispatch(GetLessons())
    dispatch(GetCourses())
    dispatch(GetExerciseDetails())
  }, [])


  const validationSchema = Yup.object().shape({
    name: Yup.string().min(1, 'Minimum 1 character').max(100, "Max 100 character allowed").required('Required'),
    course: Yup.string().required('Required'),
    unit: Yup.string().required('Required'),
    chapter: Yup.string().required('Required'),
    lesson: Yup.string().required('Required'),
  })

  const setRowSelectionData = useCallback(
    rows => {
      const selectedRowsData = rows.map(row => row.original)
      let rowsData = uniqBy(reorderedRows.concat(selectedRowsData), '_id')
      console.log('rowsData', rowsData)
      setSelectedRows(rowsData)
      setReorderedRows(rowsData)
    },
    [selectedRows]
  )

  const columns = useMemo(() => GetExerciseDetailSubSetColumns(), [])

  const handleCancel = () => {
    toggleDrawer(false)
  }

  const handleSubmit = async values => {
    let dataToSubmit = { ...values }

    if (reorderedRows?.length > 0) {
      dataToSubmit['exerciseDetails'] = reorderedRows.map(row => row?._id)
    } else {
      dataToSubmit['exerciseDetails'] = selectedRows.map(row => row?._id)
    }

    toggleDrawer(false)
    await dispatch(AddExcerciseMaster(dataToSubmit))
    refetch()
  }

  const onDataOrderChange = useCallback(rows => {
    setReorderedRows(rows)
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }
  console.log("datatable", data)
  return (
    <>
      <Box sx={{ m: 1 }}>
        <Container>
          <Typography color="textPrimary" variant="h4">
            {selectedRow?.id ? 'Edit' : 'Add'} Exercise Master
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              values,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Excercise Name"
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        label="Language"
                        name="course"
                        data={dropdowns?.courses}
                        value={values.course}
                        onChange={event => {
                          handleChange(event)
                          onCourseChange(event, setFieldValue)
                        }}
                        isRequired={true}
                        fullWidth={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        label="Course"
                        name="unit"
                        data={totalUnits}
                        value={values.unit}
                        onChange={event => {
                          handleChange(event)
                          onUnitChange(event, setFieldValue)
                        }}
                        isRequired={true}
                        fullWidth={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        label="Chapter"
                        name="chapter"
                        data={totalChapters}
                        value={values.chapter}
                        onChange={event => {
                          handleChange(event)
                          onChapterChange(event, setFieldValue)
                        }}
                        isRequired={true}
                        fullWidth={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        label="Lesson"
                        name="lesson"
                        data={totalLessons}
                        value={values.lesson}
                        onChange={handleChange}
                        isRequired={true}
                        fullWidth={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.isActive}
                        label="Status"
                        name="isActive"
                        isRequired={true}
                        onChange={handleChange}
                        data={StatusList}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <LocalTable
                      columns={columns}
                      name="exerciseDetails"
                      rowActions={false}
                      data={data}
                      isLoading={isLoading}
                      isFetching={isFetching}
                      setPagination={setPagination}
                      setRowSelectionData={setRowSelectionData}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    style={{
                      border: '1px solid lightgrey',
                    }}
                  >
                    <DraggableList
                      title="Select and reorder exercise details"
                      data={selectedRows}
                      onDataOrderChange={onDataOrderChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      color="primary"
                      // disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      color="primary"
                      onClick={handleCancel}
                      // disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="button"
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  )
}

export default AddEditExcerciseMaster
