import Config from 'src/util/config'
import API from 'src/util/api'

export function fetchDropdowns() {
  return API.get(`dropdown?type=courses,units,chapters,lessons`)
}

export function fetchProducts() {
  return API.get(`products`)
}

export function fetchLoggedInUserInfo() {
  return API.get(`me`)
}

export function fetchBanners() {
  return API.get(`banners`)
}

export function fetchLessons({ queryKey }) {
  const [_key, { pageIndex, pageSize }] = queryKey
  return API.get(`lessons?pageSize=${pageSize}&page=${pageIndex + 1}`)
}

export function fetchTranslationTransliteration({ text, sourceLang, targetLang }) {
  return API.get(`wordVocabulary/${text}?sourceLanguage=${sourceLang}&targetLanguage=${targetLang}`);
}

export function fetchFourLData(payload) {
  return API.post(Config.API_URI + 'fourl-data', payload);
}

export function addUpdateBanner(data) {
  return API.post(`banner`, data)
}

export function fetchVideos({ searchText, additionalFilters }) {
  return API.get(`videos?searchText=${searchText}${
    additionalFilters
      ? `&appliedCustomFilter=true&${toQueryString(additionalFilters)}`
      : ''
  }`)
}

export function createListFetcher(apiName) {
  return function fetchList({ queryKey }) {
    const [_key, { pageIndex, pageSize, searchText, additionalFilters }] =
      queryKey

    return API.get(
      `${apiName}?pageSize=${pageSize}&page=${pageIndex + 1}${
        searchText ? `&searchText=${searchText}` : ''
      }${
        additionalFilters
          ? `&appliedCustomFilter=true&${toQueryString(additionalFilters)}`
          : ''
      }`
    )
  }
}

// object to query string
export function toQueryString(obj) {
  return Object.keys(obj)
    .filter(
      key => obj[key] !== undefined && obj[key] !== null && obj[key] !== ''
    )
    .map(key => `${key}=${obj[key]}`)
    .join('&')
}
