import { fetchDropdowns, fetchFourLData, fetchProducts, fetchTranslationTransliteration } from 'src/api'
import { DeleteExerciseDetailMediaService } from 'src/services/exercise'
import API from 'src/util/api'
import Config from 'src/util/config'
import {
  ADD_ERROR,
  DELETE_ERROR,
  GET_ERROR,
  getLessonsWithLessThanTwoVideos,
  LOADING_ERROR
} from 'src/util/helpers'
import LocalStorageService from 'src/util/localStorageService'

import {
  SET_BANNERS_STALE,
  SET_CHAPTERS,
  SET_CHAPTERS_STALE,
  SET_COURSES,
  SET_COURSES_STALE,
  SET_DROPDOWNS, SET_ENTERTAINMENT_TRANSCRIPTIONS,
  SET_ENTERTAINMENT_VIDEOS,
  SET_ENTERTAINMENT_VIDEOS_STALE,
  SET_ENTERTAINMENT_VOCABULARIES,
  SET_EXERCISE_DETAILS,
  SET_EXERCISE_DETAILS_STALE,
  SET_EXERCISE_MASTERS,
  SET_EXERCISE_MASTERS_STALE,
  SET_FEEDBACKS,
  SET_FOURL_DATA,
  SET_LESSONS,
  SET_LESSONS_STALE,
  SET_NOTIFICATIONS, SET_PRODUCTS, SET_REPORTS,
  SET_SERVICE_LOGS,
  SET_TARNSLATION_TRANSLITERATION,
  SET_UNITS,
  SET_UNITS_STALE,
  SET_USERS,
  SIGN_IN_FAILED,
  SIGN_IN_SUCCESSFUL,
  SIGN_OUT_SUCCESSFUL,
  SIGN_UP_SUCCESSFUL,
  USER_UPDATED
} from './actionTypes'
import { setError } from './errorReducer'

export const SignInSuccessful = payload => {
  LocalStorageService.setAuthInfo(payload)
  return { type: SIGN_IN_SUCCESSFUL, payload }
}
const SignInFailed = payload => {
  return { type: SIGN_IN_FAILED, payload }
}
const SignOutSuccessful = () => {
  LocalStorageService.clearStorage()
  return { type: SIGN_OUT_SUCCESSFUL }
}
const SignUpSuccessful = payload => {
  return { type: SIGN_UP_SUCCESSFUL }
}
const UserUpdated = payload => {
  return { type: USER_UPDATED, payload }
}

const SetCourses = payload => {
  return { type: SET_COURSES, payload }
}
const SetCoursesStale = () => {
  return { type: SET_COURSES_STALE }
}
const SetServiceLogs = payload => {
  return { type: SET_SERVICE_LOGS, payload }
}
const SetUnits = payload => {
  return { type: SET_UNITS, payload }
}
const SetUnitsStale = payload => {
  return { type: SET_UNITS_STALE, payload }
}
const SetChapters = payload => {
  return { type: SET_CHAPTERS, payload }
}

const SetChaptersStale = () => {
  return { type: SET_CHAPTERS_STALE }
}

const SetReports = payload => {
  return { type: SET_REPORTS, payload }
}

const SetFeedbacks = payload => {
  return { type: SET_FEEDBACKS, payload }
}

const SetLessons = payload => {
  return { type: SET_LESSONS, payload }
}

const SetLessonsStale = () => {
  return { type: SET_LESSONS_STALE }
}
const SetBannersStale = () => {
  return { type: SET_BANNERS_STALE }
}
const SetEntertainmentTranscriptions = payload => {
  return { type: SET_ENTERTAINMENT_TRANSCRIPTIONS, payload }
}
const SetEntertainmentVideos = payload => {
  return { type: SET_ENTERTAINMENT_VIDEOS, payload }
}
const SetEntertainmentVideosStale = payload => {
  return { type: SET_ENTERTAINMENT_VIDEOS_STALE, payload }
}
const SetEntertainmentVocabularies = payload => {
  return { type: SET_ENTERTAINMENT_VOCABULARIES, payload }
}
const SetExerciseDetails = payload => {
  return { type: SET_EXERCISE_DETAILS, payload }
}

const SetExerciseDetailsStale = () => {
  return { type: SET_EXERCISE_DETAILS_STALE }
}

const SetExerciseMasters = payload => {
  return { type: SET_EXERCISE_MASTERS, payload }
}

const SetExerciseMastersStale = () => {
  return { type: SET_EXERCISE_MASTERS_STALE }
}

const SetDropdowns = payload => {
  return { type: SET_DROPDOWNS, payload }
}

const SetProducts = payload => {
  return { type: SET_PRODUCTS, payload }
}

const SetTranslationTransliteration = payload => {
  return { type: SET_TARNSLATION_TRANSLITERATION, payload }
}

const SetFourLData = payload => {
  return { type: SET_FOURL_DATA, payload }
}

const SetUsers = payload => {
  return { type: SET_USERS, payload }
}
const SetNotifications = payload => {
  return { type: SET_NOTIFICATIONS, payload }
}
export const SignIn = loginInfo => {
  return dispatch => {
    API.post('newlogin', loginInfo)
      .then(data => dispatch(SignInSuccessful(data)))
      .catch(error => dispatch(setError(error)))
  }
}

export const SignUp = params => {
  return dispatch => {
    API.post(Config.API_URI + 'signUp', params)
      .then(data => dispatch(SignInSuccessful(data)))
      .catch(error => dispatch(setError(error)))
  }
}

export const GetCourses = () => {
  return dispatch => {
    API.get(Config.API_URI + 'courses')
      .then(data => dispatch(SetCourses(data?.docs)))
      .catch(error => dispatch(setError(LOADING_ERROR('courses'))))
  }
}
export const GetUsers = () => {
  return dispatch => {
    API.get(Config.API_URI + 'users')
      .then(data => {
        dispatch(SetUsers(data?.docs))
      })
      .catch(error => dispatch(setError(LOADING_ERROR('users'))))
  }
}
export const GetServiceLogs = () => {
  return dispatch => {
    API.get(Config.API_URI + 'serviceLogs')
      .then(data => dispatch(SetServiceLogs(data)))
      .catch(error => dispatch(setError(LOADING_ERROR('service logs'))))
  }
}

export const GetUnits = filter => {
  return dispatch => {
    API.get(Config.API_URI + 'units', {
      params: filter,
    })
      .then(data => {
        dispatch(SetUnits(data.docs))
        dispatch(SetUnitsStale(data.docs))
      })
      .catch(error => dispatch(setError(LOADING_ERROR('units'))))
  }
}

export const GetChapters = filter => {
  return dispatch => {
    API.get(Config.API_URI + 'chapters', {
      params: filter,
    })
      .then(data => dispatch(SetChaptersStale(data?.docs)))
      .catch(error => dispatch(setError(LOADING_ERROR('chapters'))))
  }
}

export const GetReports = () => {
  return dispatch => {
    API.get(Config.API_URI + 'reports')
      .then(data => dispatch(SetReports(data?.docs)))
      .catch(error => dispatch(setError(LOADING_ERROR('reports'))))
  }
}

export const GetFeedbacks = () => {
  return dispatch => {
    API.get(Config.API_URI + 'feedbacks')
      .then(data => dispatch(SetFeedbacks(data?.docs)))
      .catch(error => dispatch(setError(LOADING_ERROR('feedbacks'))))
  }
}
export const GetNotifications = () => {
  return dispatch => {
    API.get(Config.API_URI + 'notification')
      .then(data => dispatch(SetNotifications(data?.docs)))
      .catch(error => dispatch(setError(LOADING_ERROR('Notification'))))
  }
}

export const AddChapter = payload => {
  return dispatch => {
    return API.post(Config.API_URI + 'chapter', payload)
      .then(() => dispatch(SetChaptersStale()))
      .catch(error => dispatch(setError(error || ADD_ERROR('courses'))))
  }
}
export const EditReport = payload => {
  return dispatch => {
    API.post(Config.API_URI + 'report', payload)
      .then(() => dispatch(GetReports()))
      .catch(error => dispatch(setError(error || ADD_ERROR('report'))))
  }
}
export const EditNotification = payload => {
  return dispatch => {
    API.post(Config.API_URI + 'notification', payload)
      .then(() => dispatch(GetNotifications()))
      .catch(error => dispatch(setError(error || ADD_ERROR('notification'))))
  }
}
export const EditFeedback = payload => {
  return dispatch => {
    API.post(Config.API_URI + 'feedback', payload)
      .then(() => dispatch(GetFeedbacks()))
      .catch(error => dispatch(setError(error || ADD_ERROR('report'))))
  }
}

export const AddExcerciseMaster = payload => {
  return dispatch => {
    return API.post(Config.API_URI + 'exerciseMaster', payload)
      .then(() => dispatch(SetExerciseMastersStale()))
      .catch(() => dispatch(setError(ADD_ERROR('exercise master'))))
  }
}

export const AddCourse = payload => {
  return dispatch => {
    return API.post(Config.API_URI + 'course', payload)
      .then(() => dispatch(SetCoursesStale()))
      .catch(error => dispatch(setError(error || ADD_ERROR('course'))))
  }
}

export const AddUnit = payload => {
  return dispatch => {
    return API.post(Config.API_URI + 'unit', payload)
      .then(() => dispatch(SetUnitsStale()))
      .catch(error => dispatch(setError(error || ADD_ERROR('unit'))))
  }
}

export const AddLesson = payload => {
  return dispatch => {
    API.post(Config.API_URI + 'lesson', payload)
      .then(() => dispatch(SetLessonsStale()))
      .catch(error => dispatch(setError(error || ADD_ERROR('lesson'))))
  }
}
export const AddBanner = payload => {
  return dispatch => {
    API.post(Config.API_URI + 'banner', payload)
      .then(() => dispatch(SetBannersStale()))
      .catch(error => dispatch(setError(error || ADD_ERROR('banner'))))
  }
}

export const AddEntertainmentVideos = payload => {
  return dispatch => {
    API.post(Config.API_URI + 'video', payload)
      .then(() => {
        dispatch(SetEntertainmentVideosStale())
      })
      .catch((err) => {
        dispatch(setError(err || ADD_ERROR('video')))
      }
    )
  }
}

export const AddEntertainmentVocabulary = payload => {
  return dispatch => {
    API.post(Config.API_URI + 'vocabulary', payload)
      .then(() => dispatch(GetEntertainmentVocabularies()))
      .catch(() => dispatch(setError(ADD_ERROR('vocabulary'))))
  }
}

export const GetLessons = filter => {
  return dispatch => {
    API.get(Config.API_URI + 'lessons', {
      params: filter,
    })
      .then(data => {
        dispatch(SetLessons(getLessonsWithLessThanTwoVideos(data.docs)))
        dispatch(SetLessonsStale())
      })
      .catch(() => dispatch(setError(LOADING_ERROR('lessons'))))
  }
}

export const GetBanners = filter => {
  return dispatch => {
    API.get(Config.API_URI + 'banners', {
      params: filter,
    })
      .then(data => {
        dispatch(SetBannersStale())
      })
      .catch(() => dispatch(setError(LOADING_ERROR('banners'))))
  }
}
export const GetEntertainmentTranscriptions = () => {
  return dispatch => {
    API.post(Config.API_URI + 'getTranscription')
      .then(data => dispatch(SetEntertainmentTranscriptions(data)))
      .catch(() => dispatch(setError(LOADING_ERROR('transcription'))))
  }
}

export const GetEntertainmentVideos = () => {
  return dispatch => {
    API.get(Config.API_URI + 'videos')
      .then(data => dispatch(SetEntertainmentVideos(data)))
      .catch(() => dispatch(setError(LOADING_ERROR('videos'))))
  }
}

export const GetEntertainmentVocabularies = () => {
  return dispatch => {
    API.get(Config.API_URI + 'vocabularies')
      .then(data => dispatch(SetEntertainmentVocabularies(data)))
      .catch(() => dispatch(setError(LOADING_ERROR('vocabularies'))))
  }
}

export const GetExerciseDetails = filter => {
  return dispatch => {
    API.get(Config.API_URI + 'exerciseDetails', {
      params: filter,
    })
      .then(data => {
        dispatch(SetExerciseDetails(data.docs))
      })
      .catch(() => dispatch(setError(LOADING_ERROR('exercise details'))))
  }
}
export const GetExerciseMasters = filter => {
  return dispatch => {
    API.get(Config.API_URI + 'exerciseMasters', {
      params: filter,
    })
      .then(data => {
        dispatch(SetExerciseMasters(data.docs))
        dispatch(SetExerciseMastersStale())
      })
      .catch(() => dispatch(setError(LOADING_ERROR('exercise master'))))
  }
}

export const DeleteCourseById = id => {
  return dispatch => {
    return API.put(`${Config.API_URI}deleteCourse/${id}`)
      .then(() => dispatch(SetCoursesStale()))
      .catch(() => dispatch(setError(DELETE_ERROR('course'))))
  }
}
export const DeleteServiceLogById = id => {
  return dispatch => {
    API.put(`${Config.API_URI}deleteServiceLog/${id}`)
      .then(() => dispatch(GetServiceLogs()))
      .catch(() => dispatch(setError(DELETE_ERROR('service log'))))
  }
}

export const DeleteUnitById = id => {
  return dispatch => {
    return API.put(`${Config.API_URI}deleteUnit/${id}`)
      .then(() => dispatch(SetUnitsStale()))
      .catch(() => dispatch(setError(DELETE_ERROR('unit'))))
  }
}

export const DeleteChapterById = id => {
  return dispatch => {
    return API.put(`${Config.API_URI}deleteChapter/${id}`)
      .then(() => dispatch(GetChapters()))
      .catch(() => dispatch(setError(DELETE_ERROR('chapter'))))
  }
}
export const DeleteReportById = id => {
  return dispatch => {
    API.put(`${Config.API_URI}deleteReport/${id}`)
      .then(() => dispatch(GetReports()))
      .catch(() => dispatch(setError(DELETE_ERROR('report'))))
  }
}
export const DeleteFeedbackById = id => {
  return dispatch => {
    API.put(`${Config.API_URI}deleteFeedback/${id}`)
      .then(() => dispatch(GetFeedbacks()))
      .catch(() => dispatch(setError(DELETE_ERROR('feedback'))))
  }
}
export const DeleteNotificationById = id => {
  return dispatch => {
    API.put(`${Config.API_URI}deleteNotification/${id}`)
      .then(() => dispatch(GetNotifications()))
      .catch(() => dispatch(setError(DELETE_ERROR('notificaiton'))))
  }
}
export const DeleteLessonById = id => {
  return dispatch => {
    return API.put(`${Config.API_URI}deleteLesson/${id}`)
      .then(() => dispatch(GetLessons()))
      .catch(() => dispatch(setError(DELETE_ERROR('lesson'))))
  }
}
export const DeleteBannerById = id => {
  return dispatch => {
    return API.put(`${Config.API_URI}deleteBanner/${id}`)
      .then(() => dispatch(SetBannersStale()))
      .catch(() => dispatch(setError(DELETE_ERROR('lesson'))))
  }
}
export const DeleteExerciseMasterById = id => {
  return dispatch => {
    return API.put(`${Config.API_URI}deleteExerciseMaster/${id}`)
      .then(() => dispatch(GetExerciseMasters()))
      .catch(() => dispatch(setError(DELETE_ERROR('exercise master'))))
  }
}

export const DeleteExerciseMasterDetailById = id => {
  return dispatch => {
    return API.put(`${Config.API_URI}deleteExerciseMasterDetail/${id}`)
      .then(() => dispatch(GetExerciseDetails()))
      .catch(() => dispatch(setError(DELETE_ERROR('exercise details'))))
  }
}

export const DeleteExerciseDetailMediaById = (id, params) => {
  return dispatch => {
    DeleteExerciseDetailMediaService(id, params)
      .then(() => {
        dispatch(GetExerciseDetails())
      })
      .catch(() => dispatch(setError(DELETE_ERROR('exercise master media'))))
  }
}

export const DeleteVideoById = id => {
  return dispatch => {
    API.put(`${Config.API_URI}deleteVideo/${id}`)
      .then(() => dispatch(SetEntertainmentVideosStale()))
      .catch(() => dispatch(setError(DELETE_ERROR('exercise master media'))))
  }
}

export const DeleteVocabularyById = id => {
  return dispatch => {
    API.put(`${Config.API_URI}deleteVocabulary/${id}`)
      .then(() => dispatch(GetEntertainmentVocabularies()))
      .catch(() => dispatch(setError(DELETE_ERROR('exercise master media'))))
  }
}

export const UpdateUserProfile = user => {
  return dispatch => {
    API.post(`${Config.API_URI}updateUser`, user)
      .then(data => {
        if (!data.error) {
          dispatch(GetUsers())
        }
      })
      .catch(error => {
        dispatch(setError(ADD_ERROR('user')))
      })
  }
}

export const AddExerciseDetails = excerciseData => {
  return dispatch => {
    API.post(`${Config.API_URI}exerciseDetail`, excerciseData)
      .then(() => dispatch(SetExerciseDetailsStale()))
      .catch(() => dispatch(setError(ADD_ERROR('exercise detail'))))
  }
}

export const GetDropdowns = () => {
  return dispatch => {
    fetchDropdowns()
      .then(data => dispatch(SetDropdowns(data)))
      .catch(() => dispatch(setError(ADD_ERROR('Dropdown values'))))
  }
}

export const GetProducts = () => {
  return dispatch => {
    fetchProducts()
      .then(data => dispatch(SetProducts(data.results)))
      .catch(() => dispatch(setError(ADD_ERROR('Products values'))))
  }
}

export const GetTranslationTranslteration = (text, sourceLang, targetLang) => {
  return dispatch => {
    return fetchTranslationTransliteration({text, sourceLang, targetLang})
      .then(data => dispatch(SetTranslationTransliteration(data)))
      .catch(() => dispatch(setError(GET_ERROR('Translation/Transliteration'))))
  }
}

export const GetFourLData = (prompt) => {
  return dispatch => {
    return fetchFourLData(prompt)
      .then(data => dispatch(SetFourLData(data)))
      .catch((err) => dispatch(setError(err)))
  }
}

export const userSignOut = user => {
  return dispatch => {
    dispatch(SignOutSuccessful())
  }
}
