import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDropdowns from 'src/hooks/useDropdowns'
import { AddChapter } from 'src/redux/actions'
import { filterByKeyValue } from 'src/util/helpers'
import * as Yup from 'yup'

import { StatusList } from '../../util/constants'
import DropdownMenu from '../helperComponents/DropdownMenu'
import LoadingButton from '../helperComponents/LoadingButton'

const AddEditChapter = ({ selectedRow, toggleDrawer, refetch }) => {
  const { courseDifficultyLevel } = useSelector(store => store.mainReducer)
  const initialValues = {
    name: (selectedRow && selectedRow?.name) || '',
    description: (selectedRow && selectedRow?.description) || '',
    course: (selectedRow && selectedRow?.course?._id) || '',
    unit: (selectedRow && selectedRow?.unit?._id) || '',
    sortOrder: (selectedRow && selectedRow?.sortOrder) || '',
    difficultyLevel:
      (selectedRow && selectedRow?.difficultyLevel) || 'All levels',
    isActive:
      selectedRow && selectedRow.isActive === undefined
        ? true
        : selectedRow && selectedRow?.isActive,
  }
  const dispatch = useDispatch()
  const { dropdowns } = useDropdowns()
  const [totalUnits, setTotalUnits] = useState([])

  useEffect(() => {
    setTotalUnits(dropdowns?.units)
  }, [dropdowns])

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(1, 'Minimum 1 letters').max(50, 'Name cannot be more than 50 characters').required('Required'),
    sortOrder: Yup.number().min(0),
    description: Yup.string().min(5, 'Minimum 5 letters').max(200, 'Description cannot be more than 200 characters').required('Required'),
    course: Yup.string().min(5, 'Minimum 5 letters').required('Required'),
    unit: Yup.string().required('Required'),
    sortOrder: Yup.number(),
  })

  const handleCancel = () => {
    toggleDrawer(false)
  }

  const handleSubmit = async values => {
    // same shape as initial values
    const reqData = selectedRow._id
      ? { ...values, _id: selectedRow._id }
      : { ...values }
    toggleDrawer(false)
    await dispatch(AddChapter(reqData))
    refetch()
  }

  const onCourseChange = useCallback(({ target: { value } }, setFieldValue) => {
    setTotalUnits(filterByKeyValue(dropdowns?.units, 'course', value))
    setFieldValue('unit', '')
  }, [])

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Container maxWidth="sm">
          <Typography color="textPrimary" variant="h4">
            {selectedRow?.id ? 'Edit' : 'Add'} a Chapter
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              values,
              handleBlur,
              handleChange,
              setFieldValue,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Name"
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      helperText={touched.description && errors.description}
                      label="Description"
                      margin="normal"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl
                      error={Boolean(touched.course && errors.course)}
                      fullWidth
                    >
                      <DropdownMenu
                        value={values.course}
                        label="Language"
                        name="course"
                        errorMsg={touched.course && errors.course}
                        onChange={event => {
                          handleChange(event)
                          onCourseChange(event, setFieldValue)
                        }}
                        data={dropdowns?.courses}
                        isRequired={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.unit}
                        label="Course"
                        name="unit"
                        onChange={handleChange}
                        data={totalUnits}
                        isRequired={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.difficultyLevel}
                        label="Difficulty Level"
                        name="difficultyLevel"
                        onChange={handleChange}
                        data={courseDifficultyLevel}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth>
                      <DropdownMenu
                        value={values.isActive}
                        label="Status"
                        name="isActive"
                        isRequired={true}
                        onChange={handleChange}
                        data={StatusList}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Sort Order"
                      margin="normal"
                      inputProps={{ min: 0 }}
                      name="sortOrder"
                      type="number"
                      defaultValue={0}
                      onChange={handleChange}
                      value={values?.sortOrder}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <LoadingButton
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      name="Save"
                    ></LoadingButton>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      color="primary"
                      onClick={handleCancel}
                      // disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="button"
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  )
}

export default AddEditChapter
